import axios from 'axios';

function clean(obj) {
  obj['extra_conditions_original'] = obj['extra_conditions']
  obj['extra_conditions'] = castToArray(obj['extra_conditions'])
  obj['procedure'] = obj.med_id.substring(obj.med_id.lastIndexOf('_')+1)
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      propName !== 'trigger_action' ? obj[propName] = '' : obj[propName] = {'type': ''}
    }
  }
  return obj
}

const castToArray = (extraConditions) => {
  let arrayConditions = []
  for (let prop in extraConditions) {
    arrayConditions.push(extraConditions[prop])
  }
  return arrayConditions
}

export const getProtocols = async (folderId, protocolId) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/protocols/${protocolId}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  try {
    const response = await axios.get(url,{headers: headers});
    const {symptom_orientation, resolution, symptom_question, prescription, tags, diagnostic} = response.data;
    let questions = [...symptom_question, ...symptom_orientation, ...resolution, ...prescription, ...diagnostic];
    questions.sort(function(a, b) {
      return a.med_id.split("_")[1] - b.med_id.split("_")[1]
    });
    return {'data': questions.map(obj => clean(obj)), 'tags':tags, 'protocol': response.data}
  } catch (error) {
    const {response} = error;
    return response;
  }
}


