import React, {useState} from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { searchDrugs } from '../../services/drugs/searchDrugs';

const SearchDrugs = ({setDrugsList}) => {
  const [charToSearch, setCharToSearch] = useState('')
  const handleSearch = async () => {
    if (charToSearch.length > 2) {
      try {
        const data = await searchDrugs(charToSearch)
        data.length === 0 ? alert('No se encontraron resultados para el termino buscado') : alert(`Se encontraron ${data.length} resultados para el termino buscado`)
        setDrugsList(data)
        setCharToSearch('')
      } catch (error) {
        alert('Ocurrio un error al realizar la busqueda')
      }
    } else {
      alert('Por favor ingrese mas de 3 caracteres para iniciar la busqueda.')
    }
  }
  return (
    <>
      <TextField
        fullWidth
        margin={'normal'}
        value={charToSearch}
        label="Buscar"
        onChange={(e) => setCharToSearch(e.target.value)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{<IconButton onClick={handleSearch}><SearchIcon/></IconButton>}</InputAdornment>,
        }}
        variant="outlined"
      />
    </>
  )
}

export default SearchDrugs
