import React, {useState} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import { exportProtocols } from '../../services/exportProtocols';
import { validateSession } from '../../helpers';

const Export = ({tenantId, environment}) => {
  const [loading, setLoading] = useState(false)

  const handleExport = async () => {
    if (!environment) {
      alert('Debe seleccionar un entorno al que exportar datos para continuar')
      return
    }
    let flag = window.confirm("¿Desea exportar todos los protocolos? Recuerde que los datos seran reemplazados");
    setLoading(true)
    if (flag){
      if (validateSession()){
        try {
          const response = await exportProtocols(tenantId, environment)
          if (response.status === 200){
            alert('La carga fue correcta')
            setLoading(false)
          }else{
            alert('Hubo un problema al exportar los protocolos')
            setLoading(false)
          }
        } catch (error) {
          alert('Hubo un problema al exportar los protocolos')
          console.log(error)
          setLoading(false)
        }
      }
    }
  };

  return (
    <>
    {!loading && <Button
      variant="contained"
      size="large"
      onClick={handleExport}
    >
      Exportar datos
    </Button>}
    {loading && <CircularProgress style={{marginLeft: 'auto', marginRight:'5%'}} color={'secondary'} />}
    </>
  )
}

export default Export
