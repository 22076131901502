import React from 'react'
import {
  TextField
} from '@material-ui/core';

const Comment = ({comment, handleChange}) => {
  return (
    <TextField  label="Comment"
      variant="outlined"
      name="comment"                            
      fullWidth
      margin={'normal'}
      value={comment}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default Comment
