import React from 'react'
import {
  Grid
} from '@material-ui/core';
import PrescriptionTag from '../TriggerAction/PrescriptionTag';
import PrescriptionScoreTable from '../TriggerAction/PrescriptionScoreTable';

const ContainerPrescription = ({question, setQuestion, handleChange}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <PrescriptionTag prescriptionTag={question.trigger_action.prescription_tag} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <PrescriptionScoreTable prescriptionScore={question.trigger_action.prescription_score} setQuestion={setQuestion} />
      </Grid>
    </Grid>
  )
}

export default ContainerPrescription
