import React from 'react'
import {
  TextField
} from '@material-ui/core';

const PharmacologicalRecommendationsIMG = ({pharmacologicalRecommendationsIMG, handleChange}) => {
  return (
    <TextField  label="Recomendaciones Farmacologicas Imagen"
      variant="outlined"
      name={'pharmacological_recommendations_img'}
      multiline
      fullWidth
      margin={'normal'}
      value={pharmacologicalRecommendationsIMG || ''}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default PharmacologicalRecommendationsIMG
