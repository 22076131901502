import React from 'react'
import {
  TextField
} from '@material-ui/core';

const ConditionTrue = ({conditionTrue, handleChange, index}) => {
  return (
    <TextField  label="Condition True"
      variant="outlined"                            
      fullWidth
      name='condition_true'
      margin={'normal'}
      value={conditionTrue}
      onChange={({target}) => handleChange(index, target)}
      type="text" />
  )
}

export default ConditionTrue
