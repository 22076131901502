import React,{useState, useEffect} from 'react';
import MaterialTable from '@material-table/core';
import { Checkbox } from '@material-ui/core';
import { getTagsMap } from '../../services/getTagsMap';
import { newTagsMap } from '../../services/newTagsMap';
import { deleteTagsMap } from '../../services/deleteTagsMap';
import { updateTagsMap } from '../../services/updateTagsMap';
import { getTenants } from '../../services/tenants/getTenants';
import SelectTenant from '../Tenants/SelectTenant';

export default function TagsTable() {
  const [tagsMap, setTagsMap] = useState([])
  const [tenants, setTenants] = useState([])

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await getTagsMap();
        setTagsMap(data)
      } catch (error) {
        alert('Error al traer el mapeo de tags')
      }
    }
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants()
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchTags()
    fetchTenants()
    return () => {
      
    }
  }, [])

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "ID", field: "id", hidden: true},
          { title: "Nombre", field: "name", width: "15%"},
          { title: "MED ID", field: "med_id", width: "25%"},
          { title: "Profile Attribute", field: "profile_attribute", width: "40%"},
          { 
            title: "Tenant", 
            field: "tenant_id",
            width: "20%",
            render: rowData => {
              let filterTenant = tenants.filter(e => e.id === rowData.tenant_id)
              return filterTenant.length ? filterTenant[0].name : rowData.tenant_id
            },
            editComponent: (props) => (
              <SelectTenant 
                tenants={tenants}
                value={props.value}
                onChange={(event) =>
                  props.onChange(event.target.value)
              }
              />),
          },
          { title: "System",
            field: "system",
            type: 'boolean',
            hidden: true,
            render: rowData => {
              return <Checkbox checked={rowData.system} disabled />
            }
          },
        ]}
        editable={{
          onRowAdd: newData =>
            newTagsMap(newData).then((data) => {
              setTagsMap([...tagsMap, data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar crear un nuevo tag.');
                console.log(e)
              }
            ),
          onRowUpdate: (newData, oldData) =>
            updateTagsMap(newData).then((data) => {
              let newTagsMap = tagsMap.filter(obj => obj.id !== data.id)
              setTagsMap([...newTagsMap, data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar editar un tag.');
                console.log(e)
              }
            ),
          onRowDelete: oldData =>
            deleteTagsMap(oldData).then((data) => {
              let newTagsMap = tagsMap.filter(obj => obj.id !== oldData.id)
              setTagsMap([...newTagsMap])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar borrar un tag.');
                console.log(e)
              }
            ),
          }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 25,
          columnsButton: true,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          paging: true,
          showTitle: false,
          addRowPosition: "first",
        }}
        data={tagsMap}
      />
    </div>
  );
}
