import React from 'react'
import {
  TextField
} from '@material-ui/core';

const GoTo = ({goTo, handleChange}) => {
  return (
    <TextField  label="Ir a"
      variant="outlined"                            
      fullWidth
      name='go_to'
      margin={'normal'}
      value={goTo}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default GoTo
