import React from 'react'
import {
  TextField
} from '@material-ui/core';

const Photo = ({photo, handleChange}) => {
  return (
    <TextField  label="Photo"
      variant="outlined"
      name="photo"
      fullWidth
      margin={'normal'}
      value={photo}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default Photo
