import React from 'react'
import {
  TextField
} from '@material-ui/core';

const ConditionCompare = ({conditionCompare, handleChange}) => {
  return (
    <TextField  label="Condition Compare"
      variant="outlined"                            
      fullWidth
      name='condition_compare'
      margin={'normal'}
      value={conditionCompare}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default ConditionCompare
