import React from 'react'
import {
  TextField
} from '@material-ui/core';

const Observation = ({observation, handleChange}) => {
  return (
    <TextField  label="Observation"
      variant="outlined"
      name={"observation"}
      fullWidth
      margin={'normal'}
      value={observation || ''}
      multiline
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default Observation
