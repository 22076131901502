import React from 'react';
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import Navbar from '../components/Navbar/Navbar'
import { validateSession } from '../helpers';
import ExportProtocols from '../components/ExportProtocols/ExportProtocols';


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
    marginTop: '10%',
    minHeight: '100vh',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function Export({match}) {
  const classes = useStyles();

  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <ExportProtocols tenantId={match.params.id} />
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  );
}
