import React from 'react'
import {Button} from '@material-ui/core';
const textRegex = /En base a sus respuestas(.*?)Explicación del cuadro/gim;
const explanationRegex = /Explicación del cuadro(.*?)Derivación/gim;
const derivationRegex = /Derivación:(.*?)Recomendaciones farmacológicas/gim;
const pharmacologicalRegex = /Recomendaciones farmacológicas:(.*?)Recomendaciones no farmacológicas/gim;
const nonPharmacologicalRegex = /Recomendaciones no farmacológicas:(.*?)Pautas de alarma/gim;
const alarmsRegex = /Pautas de alarma:(.*?)Seguimiento:/gim;
const tracingRegex = /Seguimiento:(.*?)Puedo/gim;

const FormatResolution = ({question, setQuestion}) => {
  const formatResolutionFromText = () => {
    try {
      let textWithouthLineBreaks = question.text.replace(/(\n)/gm, '')
      setQuestion(prevState => ({...prevState, text: `*Diagnóstico:*\n${textWithouthLineBreaks.match(textRegex)[0].replace('Explicación del cuadro', '').trim()}`}))
      setQuestion(prevState => ({...prevState, explanation: textWithouthLineBreaks.match(explanationRegex)[0].replace('Derivación', '').trim().replace('Explicación del cuadro','*Explicación del caso*')}))
      setQuestion(prevState => ({...prevState, observation: textWithouthLineBreaks.match(derivationRegex)[0].replace('Recomendaciones farmacológicas', '').trim()}))
      setQuestion(prevState => ({...prevState, pharmacological_recommendations_pdf: textWithouthLineBreaks.match(pharmacologicalRegex)[0].replace('Recomendaciones no farmacológicas', '').trim().replace('Recomendaciones farmacológicas','*Recomendaciones farmacológicas*')}))
      setQuestion(prevState => ({...prevState, pharmacological_recommendations: textWithouthLineBreaks.match(pharmacologicalRegex)[0].replace('Recomendaciones no farmacológicas', '').trim().replace('Recomendaciones farmacológicas','*Recomendaciones farmacológicas*')}))
      setQuestion(prevState => ({...prevState, non_pharmacological_recommendations_pdf: textWithouthLineBreaks.match(nonPharmacologicalRegex)[0].replace('Pautas de alarma', '').trim().replace('Recomendaciones no farmacológicas','*Recomendaciones generales*')}))
      setQuestion(prevState => ({...prevState, non_pharmacological_recommendations: textWithouthLineBreaks.match(nonPharmacologicalRegex)[0].replace('Pautas de alarma', '').trim().replace('Recomendaciones no farmacológicas','*Recomendaciones generales*')}))
      setQuestion(prevState => ({...prevState, alarm_guidelines: textWithouthLineBreaks.match(alarmsRegex)[0].replace('Seguimiento:', '').trim().replace('Pautas de alarma','*Pautas de alarma*')}))
      setQuestion(prevState => ({...prevState, tracing: textWithouthLineBreaks.match(tracingRegex)[0].replace('*¿Puedo', '').trim().replace('Seguimiento','*Seguimiento*')}))
      setQuestion(prevState => ({...prevState, footer: '*¿Puedo ayudarle con algo más hoy?*'}));
    } catch (error) {
      alert('No es posible formatear esta resolución')
    }
  }
  return (
    <>
      <Button variant="contained"
          onClick={formatResolutionFromText}
          align={'right'}
          disabled={question.explanation !== ''}
          color="primary">
          Formatear Resolucion
      </Button>
    </>
  )
}

export default FormatResolution
