import React,{ memo, useState } from 'react';
import {
  Grid, TextField, Button, Divider, CircularProgress
} from '@material-ui/core';
import ActiveQuestion from './UserForm/ActiveQuestion'
import Procedure from './UserForm/Procedure'
import Text from './UserForm/Text'
import Comment from './UserForm/Comment'
import Photo from './UserForm/Photo'
import Type from './UserForm/TriggerAction/Type';
import ContainerChoice from './UserForm/Container/ContainerChoice'
import ContainerNumber from './UserForm/Container/ContainerNumber'
import ContainerString from './UserForm/Container/ContainerString'
import ContainerWindow from './UserForm/Container/ContainerWindow';
import ContainerFormula from './UserForm/Container/ContainerFormula';
import ContainerPrescription from './UserForm/Container/ContainerPrescription';
import ContainerGoTo from './UserForm/Container/ContainerGoTo';
import {newQuestion} from '../../services/newQuestion';
import {updateQuestion} from '../../services/updateQuestion';
import ValueOf from './UserForm/TriggerAction/ValueOf';
import ContainerDate from './UserForm/Container/ContainerDate';
import MultipleAnswers from './UserForm/MultipleAnswers';
import ContainerExtraConditions from './UserForm/Container/ContainerExtraConditions';
import ContainerDatabase from './UserForm/Container/ContainerDatabase';
import Observation from './UserForm/Observation';
import { replaceCharacter } from '../../services/replaceCharacter';
import ContainerImage from './UserForm/Container/ContainerImage';
import ContainerResolution from './UserForm/Resolution/ContainerResolution';
import TemplateName from './UserForm/TemplateName';


const UserForm = memo(({folderId, protocolId, protocol, questions, setQuestions, question, setQuestion, tags}) => {
  const triggerActions = question.trigger_action
  const medId = question.med_id ? question.med_id : ''
  const [copyFrom, setCopyFrom] = useState('')
  const [loading, setLoading] = useState(false)

  const handleCopyFrom = () => {
    let objIndex = questions.findIndex((obj => obj.med_id === copyFrom))
    if (objIndex !== -1){
      let newTriggerAction = {...questions[objIndex].trigger_action}
      let newExtraConditions = [...questions[objIndex].extra_conditions]
      setQuestion(prevState => ({...prevState, trigger_action: {...newTriggerAction}, extra_conditions: [...newExtraConditions]}))
    } else{
      alert('No se encontro el med id')
    }
  }

  const handleChange = ({name, value, checked}) => {
    switch (name) {
      case 'active':
        setQuestion(prevState => ({...prevState, [name]: checked}))
        break;
      case 'procedure':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          let prevMedId = question.med_id.substring(0, question.med_id.lastIndexOf('_') + 1);
          question.trigger_action = {type: ''};   
          return { ...question, [name]: value, med_id: prevMedId+value }; 
        });
        break;
      default:
        setQuestion(prevState => ({...prevState, [name]: value}));
        break;
    }
    if (name === 'active'){
      setQuestion(prevState => ({...prevState, [name]: checked}))
    } else {
      setQuestion(prevState => ({...prevState, [name]: value}));
    }
  }

  const handleChangeTriggerAction = ({name, value}) => {
    setQuestion(prevState => {
      let question = Object.assign({}, prevState);
      question.trigger_action[name] = value;   
      return { ...question }; 
    });
  }

  const disableButton = () => {
    switch (question.procedure) {
      case 'P':
        return !(question.text && question.procedure && triggerActions.type && triggerActions.prescription_tag)
      default:
        return !(question.text && question.procedure)
    }
  }

  const clearState = () => {
    const initialStateQuestion = {
      "med_id": "",
      "protocol": protocol,
      "protocol_id": protocolId,
      "active": true,
      "multiple_answers": false,
      "extra_conditions": [],
      "procedure": "",
      "text": "",
      "comment": "",
      "photo": "",
      "trigger_action": {
        "type": ""
      },
      "version": ""
    };
    setQuestion(initialStateQuestion);
  }

  const postUpdateQuestion = async () => {
    let aux_questions = questions ? [...questions] : [];
    if (question.id) {
      try {
        let objIndex = aux_questions.findIndex((obj => obj.id === question.id))
        let oldProcedure = aux_questions[objIndex].procedure
        if (oldProcedure === "R" && question.procedure === "SQ"){
          alert('No puede cambiar el procedure de SQ a R')
          throw new Error('error al cambiar el procedure')
        }
        const data = await updateQuestion(folderId, question);
        aux_questions[objIndex] = data
        setQuestions(aux_questions);
        clearState();
        document.querySelectorAll('[value="'+data.med_id+'"]')[0].scrollIntoView()
      } catch (error) {
        alert('Hubo un error al enviar la pregunta')
        console.log(error)
      }
    } else {
      alert('Ocurrio un error, refresque la pagina e intente nuevamente')
    }
  };
  
  const postNewQuestion = async () => {
    setLoading(true)
    let aux_questions = questions ? [...questions] : []
    try {
      const data = await newQuestion(folderId, protocolId, question)
      aux_questions.push({...data})
      setQuestions(aux_questions)
      clearState()
      window.location.reload()
    } catch (error) {
      alert('Hubo un error al enviar la pregunta')
      console.log(error)
      setLoading(false)
    } 
  }

    return (
        <>
          <form noValidate autoComplete="off">
            {question.id ? <Grid container>
              <Divider style={{width:'100%', margin: '2% 0%'}} />
              <Grid container id={'containerHeader'}>
                <Grid item xs={12}>
                  <h4>Modificar una pregunta:</h4>
                </Grid>
                <Grid item xs={4}>
                  <TextField  label="Med_Id"
                      variant="outlined"
                      disabled
                      name={'med_id'}                            
                      fullWidth
                      margin={'normal'}
                      value={medId}
                      type="text" />
                </Grid>
                <Grid item xs={4}>
                  <TextField  label="Protocol"
                      variant="outlined"
                      disabled
                      name={'protocol'}                            
                      fullWidth
                      margin={'normal'}
                      value={protocol}
                      type="text" />
                </Grid>
                <Grid item xs={4}>
                  <TextField  label="Protocol ID"
                      disabled
                      variant="outlined"
                      name={'protocol_id'}                            
                      fullWidth
                      margin={'normal'}
                      value={protocolId}
                      type="text" />
                </Grid>
                <Grid item xs={1}>
                  <ActiveQuestion active={question.active} handleChange={handleChange} />
                </Grid>
                <Grid item xs={5}>
                  <MultipleAnswers multipleAnswers={question.multiple_answers} handleChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <Procedure procedure={question.procedure} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <Text text={question.text} handleChange={handleChange} />
                </Grid>
              </Grid>
              {question.procedure !== 'R' && <Grid container id={'containerGeneral'}>
                <Grid item xs={12}>
                  <Comment comment={question.comment} handleChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                  <Photo photo={question.photo} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12}>
                  <TemplateName templateName={question.template_name} handleChange={handleChange} />
                </Grid>
              </Grid>}
              <Grid item xs={12}>
                  <Observation observation={question.observation} handleChange={handleChange} />
              </Grid>
              {question.procedure === 'R' && <Grid container id={'containerResolution'}>
                <ContainerResolution question={question} handleChange={handleChange} setQuestion={setQuestion} />
              </Grid>}
              <Grid container>
                <Divider style={{width:'100%', margin: '2% 0%'}} />
                <Grid item xs={6}>
                  <h4>Trigger actions:</h4>
                </Grid>
                <Grid item xs={3}>
                  <TextField  label="Copiar desde"
                      variant="outlined"
                      name={'copy_from_medid'}                            
                      fullWidth
                      value={copyFrom}
                      onChange={({target}) => setCopyFrom(target.value)}
                      type="text" />
                </Grid>
                <Grid item xs={3}>
                  <Button variant="contained"
                      style={{height: '100%'}}
                      onClick={handleCopyFrom}
                      align={'right'}
                      color="primary">
                      Copiar desde med_id
                  </Button>
                </Grid>
                {((question.procedure) && (question.text)) && (question.trigger_action) ? 
                  <Grid item xs={6}>
                    <Type type={question.trigger_action.type} setQuestion={setQuestion} question={question} questions={questions} />
                </Grid> : '' }
                {((question.procedure) && (question.text)) && (question.trigger_action) ? 
                  <Grid item xs={6}>
                    <ValueOf valueOf={question.trigger_action.value_of} handleChange={handleChangeTriggerAction}/>
                </Grid> : '' }
                { triggerActions.type === 'choice' && <ContainerChoice question={question} setQuestion={setQuestion} />}
                { triggerActions.type === 'string' && <ContainerString question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'date' && <ContainerDate question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'number' && <ContainerNumber question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'window' && <ContainerWindow question={question} handleChange={handleChangeTriggerAction} questions={questions} tags={tags}/>}
                { triggerActions.type === 'formula' && <ContainerFormula question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'go_to' && <ContainerGoTo question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'image' && <ContainerImage question={question} handleChange={handleChangeTriggerAction} />}
                { triggerActions.type === 'database' && <ContainerDatabase question={question} setQuestion={setQuestion} handleChange={handleChangeTriggerAction} />}
                { (question.procedure === 'P' && triggerActions.type) && <ContainerPrescription question={question} setQuestion={setQuestion} handleChange={handleChangeTriggerAction} />}
              </Grid>
              <Grid container>
                <Divider style={{width:'100%', margin: '2% 0%'}} />
                {question.procedure !== 'R' && <ContainerExtraConditions question={question} setQuestion={setQuestion} />}
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained"
                    style={{float: 'right'}}
                    onClick={postUpdateQuestion}
                    align={'right'}
                    disabled={disableButton()}
                    color="primary">
                    Modificar pregunta
                </Button>
                <Button variant="contained"
                    style={{float: 'right'}}
                    onClick={clearState}
                    align={'right'}
                    color="primary">
                    Limpiar
                </Button>
                <Button variant="contained"
                    style={{float: 'right', display: "none"}}
                    onClick={() => replaceCharacter(folderId, protocolId)}
                    align={'right'}
                    color="primary">
                    Replace
                </Button>
              </Grid>
            </Grid> :
            <Grid container>
              <Divider style={{width:'100%', margin: '2% 0%'}} />
              <Grid item xs={12}>
                <h4>Crear una nueva pregunta:</h4>
              </Grid>
              <Grid item xs={6} style={{marginLeft:'auto'}}>
                <Procedure procedure={question.procedure} handleChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                <Text text={question.text} handleChange={handleChange} />
              </Grid>
              <Grid item xs={12}>
                {loading ? 
                  <CircularProgress style={{marginLeft:'12px', float:'right'}} /> :
                  <Button variant="contained"
                      style={{float: 'right'}}
                      onClick={postNewQuestion}
                      align={'right'}
                      disabled={!(question.procedure && question.text) || loading}
                      color="primary">
                      Crear pregunta
                  </Button>}
                <Button variant="contained"
                    style={{float: 'right'}}
                    onClick={clearState}
                    align={'right'}
                    color="primary">
                    Limpiar
                </Button>
              </Grid>
            </Grid> }
          </form>
          <div style={{ textAlign: 'left', display: 'none' }}>
              <ul>
                  {
                    questions?.map((questions, key) =>
                        <li key={key}>{JSON.stringify(questions)}</li>
                    )
                  }
              </ul>
          </div>
        </>
    );
});

export default UserForm;