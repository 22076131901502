import React from 'react'
import {List, ListItem, ListItemText, Dialog, DialogTitle} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { copyFolder } from '../../services/folders/copyFolder';

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '20%',
    padding: '1%'
  },
}));

const TenantDialog = ({open, handleClose, folderId, tenants}) => {
  const classes = useStyles();

  const handleClick = async (folderId, tenantId) => {
    try {
      await copyFolder(folderId, tenantId)
      alert('Carpeta copiada con exito')
      window.location.reload()
    } catch (error) {
      alert('Ocurrio un error al copiar la carpeta')
      console.log(error)
    }
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth="lg"
        classes={{
          paperWidthLg: classes.dialog, // class name, e.g. `classes-nesting-root-x`
        }}
      >
        <DialogTitle>Tenant</DialogTitle>
        {
          tenants.map((tenant) => (
            <List>
              <ListItem key={tenant.id} button onClick={() => handleClick(folderId, tenant.id)}>
                <ListItemText primary={tenant.name} />
              </ListItem>
            </List>
          ))
        }
        
      </Dialog>
  )
}

export default TenantDialog
