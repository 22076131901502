import React, {useEffect, useState} from 'react'
import { Dialog, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTenants } from '../../services/tenants/getTenants'
import SelectTenant from '../Tenants/SelectTenant';
import { newFolder } from '../../services/folders/newFolder';
import { addProtocol } from '../../services/addProtocol';
import { newQuestion } from '../../services/newQuestion';
import { getProtocols } from '../../services/getProtocols';

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '50%',
    padding: '3%'
  },
}));

const CloneProtocolDialog = ({open, handleClose, folder, folders, protocol}) => {
  const classes = useStyles();

  const [tenants, setTenants] = useState([])
  const [selectedTenant, setSelectedTenant] = useState('')

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants();
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchTenants()
    return () => {
      
    }
  }, [])

  const handleCancel = () => {
    setSelectedTenant('')
    handleClose()
  }

  const handleClick = async () => {
    let destinationFolder = folders.find(e => e.name === folder.name && e.assigned_tenant === selectedTenant)
    let destinationProtocol = destinationFolder?.protocols.find(e => e.name === protocol.name)
    const getProtocolsResponse = await getProtocols(folder.id, protocol.id)
    const questionsToClone = getProtocolsResponse.data

    if (destinationFolder) {
      if (!destinationProtocol) {
        let {data} = await addProtocol(protocol.name, destinationFolder)
        destinationProtocol = data
      }
    } else {
      const newFolderResponse = await newFolder({name: folder.name, assigned_tenant: selectedTenant})
      destinationFolder = newFolderResponse.data
      let addProtocolResponse = await addProtocol(protocol.name, destinationFolder)
      destinationProtocol = addProtocolResponse.data
    }

    const data = await Promise.allSettled(questionsToClone.map(async (question) => {
      const {id, ...clonedQuestion} = question;

      if (clonedQuestion.extra_conditions_original){
        clonedQuestion.extra_conditions = clonedQuestion.extra_conditions_original
        delete clonedQuestion.extra_conditions.original
      }
      await newQuestion(destinationFolder.id, destinationProtocol.id, clonedQuestion)
    }));

    data.some(e => e.status === 'rejected') ? alert('Error al clonar el protocolo: algunas preguntas no se copiaron con exito') : alert('Protocolo clonado exitosamente')
    handleCancel()
    window.location.reload()
    
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth="lg"
      classes={{
        paperWidthLg: classes.dialog,
      }}
    >
      <div>Protocol: {protocol.name}</div>
      <div>Folder: {folder.name}</div>
      <div>Tenant: {tenants.find(e => e.id === selectedTenant)?.name}</div>
      <SelectTenant tenants={tenants} value={selectedTenant} onChange={(event) => setSelectedTenant(event.target.value)} />
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          CANCELAR
        </Button>
        <Button onClick={handleClick} color="primary">
          CLONAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloneProtocolDialog
