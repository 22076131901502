import React from 'react'
import {
  TextField
} from '@material-ui/core';

const ConditionFormula = ({conditionFormula, handleChange, index}) => {
  return (
    <TextField  label="Condition Formula"
      variant="outlined"                            
      fullWidth
      name='condition_formula'
      margin={'normal'}
      value={conditionFormula}
      onChange={({target}) => handleChange(index, target)}
      inputProps={{
        autoComplete: 'on',
      }}
      type="text" />
  )
}

export default ConditionFormula
