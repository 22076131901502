import axios from 'axios';

const cleanTriggerActions = (triggerAction, procedure) => {
  let {type, value_of, prescription_tag, condition_true, prescription_score, 
    condition_false, condition_compare, condition_op, 
    condition_value, window_list, condition_formula, date, 
    db_mode, db_op, db_name, db_query, db_order_by, db_line_template, db_update, db_confirm_execution,
    ...rest } = triggerAction
  let newTriggerAction = {type}
  if (value_of){
    newTriggerAction = {...newTriggerAction, value_of}
  }
  if (procedure === 'P'){
    newTriggerAction = {...newTriggerAction, prescription_tag, prescription_score}
  }
  switch (triggerAction.type) {
    case 'choice':
      newTriggerAction = {...newTriggerAction, ...rest}
      return {...newTriggerAction};
    case 'number':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false}
      return {...newTriggerAction};
    case 'string':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false}
      return {...newTriggerAction};
    case 'image':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false}
      return {...newTriggerAction};
    case 'formula':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false, condition_formula}
      return {...newTriggerAction};
    case 'window':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false, window_list, condition_value, condition_op, condition_compare}
      return {...newTriggerAction};
    case 'date':
      newTriggerAction = {...newTriggerAction, condition_true, condition_false, date}
      return {...newTriggerAction};
    case 'database':
      newTriggerAction = {...newTriggerAction, db_mode, db_op, db_name, db_query, db_order_by, db_line_template, db_update, db_confirm_execution, ...rest}
      return {...newTriggerAction};
    default:
      return {...newTriggerAction};
  }
}

const castToArray = (extraConditions) => {
  let arrayConditions = []
  for (let prop in extraConditions) {
    arrayConditions.push(extraConditions[prop])
  }
  return arrayConditions
}

const castToObject = (extraConditions) => {
  return extraConditions.reduce(function(result, item, index, array) {
    if (item.type){
      result[`condition_${index+2}`] = item; //a, b, c
    }
    return result;
  }, {})
}

export const updateQuestion = async (folderId, question) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/protocols/${question.protocol_id}/${question.procedure}/${question.id}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    question.trigger_action = cleanTriggerActions(question.trigger_action, question.procedure)
    const response = await axios.patch(url,{...question, extra_conditions: castToObject(question.extra_conditions)},{headers: headers});
    return {...response.data, extra_conditions: castToArray(response.data.extra_conditions), procedure: response.data.med_id.substring(response.data.med_id.lastIndexOf('_')+1)};
  } catch (error) {
    const {response} = error;
    return response;
  }
}


