import React from 'react'
import {
  TextField
} from '@material-ui/core';

const Date = ({date, handleChange}) => {
  return (
    <TextField  label="Date"
      variant="outlined"                            
      fullWidth
      name='date'
      margin={'normal'}
      value={date}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default Date
