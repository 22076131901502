import React from 'react'
import {
  TextField
} from '@material-ui/core';

const DBConfirmExecution = ({dbConfirmExecution, handleChange}) => {
  return (
    <TextField  label="DB Confirm Execution"
      variant="outlined"
      name={'db_confirm_execution'}
      multiline
      fullWidth
      margin={'normal'}
      value={dbConfirmExecution}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default DBConfirmExecution
