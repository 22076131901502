import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const Type = ({type, setQuestion, question, questions}) => {
  let objIndex = questions.findIndex((obj => obj.id === question.id)) + 1
  let newMedId = ''
  const handleChange = ({value}) => {
    switch (value) {
      case 'choice':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          return { ...question }; 
        });
        break;
      case 'sino':
        if (objIndex >= questions.length){
          alert('No existe una pregunta siguiente dentro del protocolo')
        }else{
          newMedId = questions[objIndex].med_id
        }
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': 'choice', "si": newMedId, "no": newMedId};
          return { ...question }; 
        });
      break
      case 'resolution':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': 'choice', "si": 'P00pxSALUDOxSMG_70_SQ', "no": 'P00pxSALUDOxSMG_96_R'};
          return { ...question }; 
        });
      break;
      case 'string':
        if (objIndex >= questions.length){
          alert('No existe una pregunta siguiente dentro del protocolo')
        }else{
          newMedId = questions[objIndex].med_id
        }
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.condition_false = question.med_id;
          question.trigger_action.condition_true = newMedId;
          return { ...question }; 
        });
        break;
      case 'image':
        if (objIndex >= questions.length){
          alert('No existe una pregunta siguiente dentro del protocolo')
        }else{
          newMedId = questions[objIndex].med_id
        }
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.condition_false = question.med_id;
          question.trigger_action.condition_true = newMedId;
          return { ...question }; 
        });
        break;
      case 'number':
        if (objIndex >= questions.length){
          alert('No existe una pregunta siguiente dentro del protocolo')
        }else{
          newMedId = questions[objIndex].med_id
        }
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.condition_false = question.med_id;
          question.trigger_action.condition_true = newMedId;
          return { ...question }; 
        });
        break;
        case 'date':
          setQuestion(prevState => {
            let question = Object.assign({}, prevState);
            question.trigger_action = {...question.trigger_action, 'type': value};
            question.trigger_action.condition_false = question.med_id;
            question.trigger_action.condition_true = '';
            question.trigger_action.date = '';
            return { ...question }; 
          });
          break;
      case 'window':
      setQuestion(prevState => {
        let question = Object.assign({}, prevState);
        question.trigger_action = {...question.trigger_action, 'type': value};
        question.trigger_action.window_list = [];
        question.trigger_action.condition_value = '';
        question.trigger_action.condition_op = '';
        question.trigger_action.condition_compare = '';
        return { ...question }; 
      });
      break;
      case 'formula':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.condition_formula = '';
          question.trigger_action.condition_true = '';
          question.trigger_action.condition_false = '';
          return { ...question }; 
        });
        break;
      case 'go_to':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.go_to = '';
          return { ...question }; 
        });
          break;
      case 'database':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          question.trigger_action.db_mode = '';
          question.trigger_action.db_op = '';
          question.trigger_action.db_name = '';
          question.trigger_action.db_query = '';
          question.trigger_action.db_order_by = '';
          question.trigger_action.db_line_template = '';
          question.trigger_action.db_confirm_execution = '';
          question.trigger_action.db_update = '';
          return { ...question }; 
        });
          break;
      case '':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.trigger_action = {...question.trigger_action, 'type': value};
          return { ...question }; 
        });
        break;
      default:
        break;
    }
  }

  return (
    <FormControl variant="outlined" style={{minWidth: 150}}>
      <InputLabel>Type</InputLabel>
      <Select                            
      fullWidth
      value={type}
      onChange={({target}) => {
        handleChange(target); 
      }}
      label="Type"
      name='type'
      >
      <MenuItem value="">
          <em>None</em>
      </MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'number'}>Numero</MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'string'}>Texto</MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'image'}>Imagen</MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'window'}>Window</MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'formula'}>Formula</MenuItem>
      <MenuItem value={'choice'}>Choice</MenuItem>
      <MenuItem value={'database'}>Database</MenuItem>
      <MenuItem value={'sino'}>Choice si/no</MenuItem>
      <MenuItem disabled={question.procedure !== 'R'} value={'resolution'}>Choice resolution</MenuItem>
      <MenuItem disabled={question.procedure === 'R'} value={'date'}>Fecha</MenuItem>
      </Select>
    </FormControl>
  )
}

export default Type
