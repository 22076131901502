import axios from 'axios';

export const changePassword = async ({password, newPassword, confirmPassword}) => {
  const url = `${process.env.REACT_APP_API_URL}/change_password?actual_pwd=${password}&new_pwd=${newPassword}&confirm_pwd=${confirmPassword}`
  const headers = {
    'Authorization': `Bearer ${await localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.post(url,{}, {headers: headers});
    return response
  } catch (error) {
    const {response} = error
    return response
  }
}

