import React from 'react'
import {
  TextField
} from '@material-ui/core';

const PrescriptionTag = ({prescriptionTag, handleChange}) => {
  return (
    <TextField  label="Prescription Tag"
      variant="outlined"                            
      fullWidth
      name='prescription_tag'
      margin={'normal'}
      value={prescriptionTag || ''}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default PrescriptionTag
