import React,{useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TextField, IconButton} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function AnswersTable({question, setQuestion, index}) {
  let { type, ...rest } = question.extra_conditions[index]
  const rows = rest

  const [answer, setAnswer] = useState('')
  const [next, setNext] = useState('')

  const handleClick = (key) => {
    let { [key]: value, ...rest } = rows
    setQuestion(prevState => {
      let question = Object.assign({}, prevState);
      question.extra_conditions[index] = {'type': 'choice', ...rest}
      return { ...question }
    });
  }


  const handleAnswers = () => {
    if (answer !== '' && next !== '') {
      setQuestion(prevState => {
        let question = Object.assign({}, prevState);
        question.extra_conditions[index][answer] = next;   
        return { ...question }
      });
      setAnswer('');
      setNext('');
    } else{
        alert('Debe completar answer y next para añadir una respuesta');
    }
  }

  return (
    <Grid container>
      <Grid item xs={3}>
          <TextField  label="Answer"
              name='answer'
              variant="outlined"                            
              fullWidth
              margin={'normal'}
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              type="text" />
      </Grid>
      <Grid item xs={2}>
          <TextField  label="Next"
              name='next'
              variant="outlined"                            
              fullWidth
              margin={'normal'}
              value={next}
              onChange={(e) => setNext(e.target.value)}
              type="text" />
      </Grid>
      <Grid item xs={1}>
          <IconButton color="primary" style={{marginTop: 15}} onClick={handleAnswers}>
              <ForwardIcon fontSize='large' />
          </IconButton>
      </Grid>
      <Grid item xs={6}>
        <TableContainer style={{float: 'right', maxWidth: '80%'}} component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Respuesta</TableCell>
                <TableCell>Next</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows ? Object.entries(rows).map((key, index) => (
                <TableRow key={index}>
                  <TableCell>{JSON.stringify(key).slice(1, -1).split(',')[0].slice(1, -1)}</TableCell>
                  <TableCell>{JSON.stringify(key).slice(1, -1).split(',')[1].slice(1, -1)}</TableCell>
                  <TableCell>{<IconButton onClick={() => handleClick(JSON.stringify(key).slice(1, -1).split(',')[0].slice(1, -1))}><DeleteOutlineIcon /></IconButton>}</TableCell>
                </TableRow>
              )) : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
