import React from 'react'
import {
  Grid
} from '@material-ui/core';
import AnswersTable from '../TriggerAction/AnswersTable';

const ContainerChoice = ({question, setQuestion}) => {
  return (
    <Grid container>
      <AnswersTable question={question} setQuestion={setQuestion} />
    </Grid>
  )
}

export default ContainerChoice
