import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialog: {
    height: '90%',
    width: '90%',
  },
}));

const DialogHelper = ({open, handleClose}) => {
  const classes = useStyles();

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth="lg"
        classes={{
          paperWidthLg: classes.dialog, // class name, e.g. `classes-nesting-root-x`
        }}
      >
        <DialogTitle>Ayuda</DialogTitle>
        <DialogContent dividers={true}>
          <iframe style={{width: '100%', height: '100%'}} title={'Ayuda'} src="https://edoc-app.s3.us-east-2.amazonaws.com/formula_cheatsheet.html"/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default DialogHelper
