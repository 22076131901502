import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select, Grid, Tooltip
} from '@material-ui/core';
import titlesDictionary from '../../../../dictionaries/derivation/titles.json'
import specialtiesDictionary from '../../../../dictionaries/derivation/specialties'
import typesDictionary from '../../../../dictionaries/derivation/types'

const Referral = ({referralTo, referralToSpeciality, referralToSpecialitySecond, referralToType, handleChange}) => {
  return (
    <Grid container>
      <Grid xs={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Derivacion</InputLabel>
          <Select                            
            fullWidth
            value={referralTo}
            onChange={({target}) => {
              handleChange(target); 
            }}
            label="Derivacion Leyenda"
            name='referral_to'
          >
            <MenuItem value="">
                <em>Ninguna</em>
            </MenuItem>
            {
              titlesDictionary.map(element => {
                return (
                    <MenuItem value={element.description}>
                      <Tooltip placement="right" arrow title={<div style={{ fontSize: "medium", margin: "6px 0" }}>{element.description}</div>}>
                        <div>{element.id} - {element.title}</div>
                      </Tooltip>
                    </MenuItem>
                  )
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Especialidad</InputLabel>
          <Select                            
            fullWidth
            value={referralToSpeciality}
            onChange={({target}) => {
              handleChange(target); 
            }}
            label="Derivacion Especialidad"
            name='referral_to_speciality'
          >
            <MenuItem value="">
                <em>Ninguna</em>
            </MenuItem>
            {
              specialtiesDictionary.map(element => {
                return (
                  <MenuItem value={element.description}>
                    <Tooltip placement="right" arrow title={<div style={{ fontSize: "medium", margin: "6px 0" }}>{element.description}</div>}>
                      <div>{element.speciality}</div>
                    </Tooltip>
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={3}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Segunda Especialidad</InputLabel>
          <Select                            
            fullWidth
            value={referralToSpecialitySecond}
            onChange={({target}) => {
              handleChange(target); 
            }}
            label="Derivacion Segunda Especialidad"
            name='referral_to_speciality_second'
          >
            <MenuItem value="">
                <em>Ninguna</em>
            </MenuItem>
            {
              specialtiesDictionary.map(element => {
                return (
                  <MenuItem value={element.description}>
                    <Tooltip placement="right" arrow title={<div style={{ fontSize: "medium", margin: "6px 0" }}>{element.description}</div>}>
                      <div>{element.speciality}</div>
                    </Tooltip>
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={2}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Tipo</InputLabel>
          <Select                            
            fullWidth
            value={referralToType}
            onChange={({target}) => {
              handleChange(target); 
            }}
            label="Derivacion Tipo"
            name='referral_to_type'
          >
            <MenuItem value="">
                <em>Ninguna</em>
            </MenuItem>
            {
              typesDictionary.map(element => {
                return (
                  <MenuItem value={element.description}>
                    <Tooltip placement="right" arrow title={<div style={{ fontSize: "medium", margin: "6px 0" }}>{element.description}</div>}>
                      <div>{element.type}</div>
                    </Tooltip>
                  </MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default Referral
