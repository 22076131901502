import React from 'react'
import {
  Grid
} from '@material-ui/core';
import GoTo from '../TriggerAction/GoTo';

const ContainerGoTo = ({question, handleChange}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <GoTo goTo={question.trigger_action.go_to} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default ContainerGoTo
