import React,{useState} from 'react';
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import {TextField, Button, Grid, Paper, CircularProgress} from '@material-ui/core';
import {login} from '../services/login';


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
    marginTop: '10%',
    minHeight: '100vh',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(false)

  function handleLogin() {
    setLoading(true)
    if (username && password){
      login({'username':username, 'password':password}).then(({data, status}) => {
        if (status === 401) {
          alert('Bad credentials');
          setLoading(false)
          setPassword('');
        } else {
          let dt = new Date();
          dt.setSeconds( dt.getSeconds() + data.expire )
          let expire = new Date(dt).getTime()
          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)
          localStorage.setItem('expire', expire)
          localStorage.setItem('profile', data.profile)
          localStorage.setItem('user_id', data.user_id)
          localStorage.setItem('user_tenant', JSON.stringify(data.user_tenant))
          setUsername('');
          setPassword('');
          setLogged(true);
        };
      }).catch(
        (e) => {
          alert('Hubo un problema al intentar iniciar sesión.');
          setPassword('')
          setLoading(false)
        }
      );
    } else{
      alert('Ingrese usuario y/o contraseña')
      setLoading(false)
    }
  }

  const classes = useStyles();

  if (logged) {
    return <Redirect to='/health' />
  }

  return (
    <div className={classes.root}>
      <Grid container >
        <Paper className={classes.paper}>
          <Grid item xs={12}>
            <TextField name="username" label="Username" variant="outlined" fullWidth style={{marginTop: '12px'}} autoComplete='off' value={username} onChange={({target}) => setUsername(target.value)} onKeyPress={(e) => { if (e.key === 'Enter'){handleLogin()}}}/>
          </Grid>
          <Grid item xs={12}>
            <TextField name="password" label="Password" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={password} onChange={({target}) => setPassword(target.value)} onKeyPress={(e) => { if (e.key === 'Enter'){handleLogin()}}}/>
          </Grid>
          <Grid item xs={12}>
            {loading && <CircularProgress style={{marginTop: '12px', float:'right'}} />}
            {!loading && <Button variant="contained" color="primary" style={{marginTop: '12px', float:'right'}} onClick={handleLogin}>
              Login
            </Button>}
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
