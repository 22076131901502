import React from 'react'
import {
  TextField
} from '@material-ui/core';

const DBLineTemplate = ({dbLineTemplate, handleChange}) => {
  return (
    <TextField  label="DB Line Template"
      variant="outlined"
      name={'db_line_template'}
      multiline
      fullWidth
      margin={'normal'}
      value={dbLineTemplate}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default DBLineTemplate
