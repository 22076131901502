import React, { useState } from 'react'
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Navbar from '../components/Navbar/Navbar'
import DrugsTable from '../components/Drugs/DrugsTable';
import SearchDrugs from '../components/Drugs/SearchDrugs';
import { validateSession } from '../helpers';

const useStyles = makeStyles(() => ({
  main: {
    margin: '10% 5% 5% 5%',
    maxWidth: '90%',
  },
}));

const HealthCheck = () => {
  const classes = useStyles();
  const [drugsList, setDrugsList] = useState([])

  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <Grid container className={classes.main}>
        <Grid item xs={6}>
          <SearchDrugs setDrugsList={setDrugsList} />
        </Grid>
        <Grid item xs={12}>
          <DrugsTable drugs={drugsList} />
        </Grid>
      </Grid>
    </>
  )
}

export default HealthCheck
