import React from 'react'
import {
  Grid
} from '@material-ui/core';
import WindowList from '../TriggerAction/WindowList';
import ConditionValue from '../TriggerAction/ConditionValue';
import ConditionOp from '../TriggerAction/ConditionOp';
import ConditionCompare from '../TriggerAction/ConditionCompare';
import ConditionTrue from '../TriggerAction/ConditionTrue';
import ConditionFalse from '../TriggerAction/ConditionFalse';

const ContainerWindow = ({question, handleChange, questions, tags}) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <WindowList windowList={question.trigger_action.window_list} handleChange={handleChange} questions={questions} tags={tags} />
      </Grid>
      <Grid item xs={4}>
        <ConditionValue conditionValue={question.trigger_action.condition_value} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionOp conditionOp={question.trigger_action.condition_op} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionCompare conditionCompare={question.trigger_action.condition_compare} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionTrue conditionTrue={question.trigger_action.condition_true} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionFalse conditionFalse={question.trigger_action.condition_false} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default ContainerWindow
