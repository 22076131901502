import React, { useState } from 'react';
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Paper, CircularProgress } from '@material-ui/core'
import SelectMultipleTenant from '../components/Tenants/SelectMultipleTenant'
import Navbar from '../components/Navbar/Navbar'
import { updateUser } from '../services/users/updateUser';
import { validateSession } from '../helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
    marginTop: '10%',
    minHeight: '100vh',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function ModifyUser() {
  const [loading, setLoading] = useState(false)
  const [tenants, setTenants] = useState(JSON.parse(localStorage.getItem('user_tenant')))

  function handleModifyUser() {
    setLoading(true)
    if (tenants.length > 0){
      updateUser({'tenant': tenants}).then(({data, status}) => {
        if (status === 200){
          alert('Usuario modificado con exito.')
          setTenants(data.tenant)
          localStorage.setItem('user_tenant', JSON.stringify(data.tenant))
          setLoading(false)
        } else{
          alert('Hubo un problema al intentar modificar el usuario.')
          setLoading(false)
        }
      }).catch(
        (e) => {
          alert('Hubo un problema al intentar modificar el usuario.')
          setLoading(false)
        }
      )
    } else{
      alert('Debe seleccionar al menos un tenant')
      setLoading(false)
    }
  }

  const classes = useStyles();

  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <SelectMultipleTenant tenants={tenants} setTenants={setTenants} />
            </Grid>
            <Grid item xs={12}>
              {loading && <CircularProgress style={{float:'right'}} />}
              {!loading && <Button variant="contained" color="primary" style={{float:'right'}} onClick={handleModifyUser}>
                MODIFICAR
              </Button>}
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  );
}
