import React from 'react'
import {
  MenuItem, Select, InputLabel, FormControl
} from '@material-ui/core';

const SelectTenant = ({value, onChange, tenants}) => {

  return (
    <FormControl variant="outlined" fullWidth style={{margin: "5% 0"}}>
      <InputLabel>Tenants</InputLabel>
      <Select
        fullWidth
        label="Tenants"
        value={value}
        onChange={onChange}
        name='tenant'
      >
        {
          tenants.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}

export default SelectTenant
