import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const DBMode = ({dbMode, handleChange}) => {
  return (
    <FormControl variant="outlined" style={{minWidth: '100%', margin: '16px 0px'}} >
        <InputLabel>DB Mode</InputLabel>
        <Select                            
        fullWidth
        name={'db_mode'}
        value={dbMode || ''}
        onChange={({target}) => handleChange(target)}
        label="DB Mode"
        >
        <MenuItem value={'r'}>Lectura</MenuItem>
        <MenuItem value={'w'}>Escritura</MenuItem>
        <MenuItem value={'rw'}>Lectura y escritura</MenuItem>
        </Select>
    </FormControl>
  )
}

export default DBMode
