import React from 'react'
import {
  FormControlLabel, Checkbox
} from '@material-ui/core';

const ActiveQuestion = ({active, handleChange}) => {
  return (
    <>
      <FormControlLabel
        control={
            <Checkbox
                checked={active}
                name="active"
                onChange={({target}) => handleChange(target)}
            />
        }
        label="Active"
    />
    </>
  )
}

export default ActiveQuestion
