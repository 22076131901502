import React,{useState, useEffect} from 'react';
import MaterialTable from '@material-table/core';

import { getDrugClasses } from '../../services/drugs/getDrugsClasses';
import { newDrugClass } from '../../services/drugs/newDrugClass';
import { updateDrugClass } from '../../services/drugs/updateDrugClass';
import { deleteDrugClass } from '../../services/drugs/deleteDrugClass';

export default function DrugsClassesTable() {
  const [drugClasses, setDrugClasses] = useState([])

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await getDrugClasses();
        setDrugClasses(data)
      } catch (error) {
        alert('Error al traer el mapeo de tags')
      }
    }
    fetchTags()
    return () => {
      
    }
  }, [])

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Name", field: "name"},
          { title: "MED ID", field: "med_id"},
          { title: "Number", field: "number"},
        ]}
        editable={{
          onRowAdd: newData =>
            newDrugClass(newData).then((data) => {
              setDrugClasses([...drugClasses, data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar crear un nuevo tag.');
                console.log(e)
              }
            ),
          onRowUpdate: (newData, oldData) =>
            updateDrugClass(newData).then((data) => {
              let newDrugClass = drugClasses.filter(obj => obj.id !== data.id)
              setDrugClasses([...newDrugClass, data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar editar un tag.');
                console.log(e)
              }
            ),
          onRowDelete: oldData =>
            deleteDrugClass(oldData).then((data) => {
              let newDrugClass = drugClasses.filter(obj => obj.id !== oldData.id)
              setDrugClasses([...newDrugClass])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar borrar un tag.');
                console.log(e)
              }
            ),
          }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          paging: true,
          showTitle: false,
          addRowPosition: "first",
        }}
        data={drugClasses}
      />
    </div>
  );
}
