import React from 'react'
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Navbar from '../components/Navbar/Navbar'
import DrugsClassesTable from '../components/DrugsClasses/DrugsClassesTable';
import { validateSession } from '../helpers';

const useStyles = makeStyles(() => ({
  main: {
    margin: '10% 5% 5% 5%',
    maxWidth: '90%',
  },
}));

const HealthCheck = () => {
  const classes = useStyles();
  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <DrugsClassesTable />
        </Grid>
      </Grid>
    </>
  )
}

export default HealthCheck
