import React,{useState, useEffect} from 'react'
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Fab } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import UserForm from '../components/Protocol/UserForm'
import Tags from '../components/Protocol/Tags'
import QuestionTable from '../components/Protocol/QuestionsTable'
import Navbar from '../components/Navbar/Navbar'
import FileUpload from '../components/Protocol/UserForm/FileUpload';
import { getProtocols } from '../services/getProtocols';
import { updateProtocol } from '../services/updateProtocol';
import DialogHelper from '../components/Protocol/DialogHelper';
import { validateSession } from '../helpers';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { deleteProtocol } from '../services/deleteProtocol';

const useStyles = makeStyles(() => ({
    main: {
      margin: '5%',
      maxWidth: '90%',
    },
    upload: {
      marginTop: '1%',
    },
    tags: {
      maxWidth: '90%',
      margin: '5%',
    },
    fab: {
      position: 'fixed',
      top: '10%',
      right: '1%',
      zIndex: '+1',
    },
    fabDelete: {
      position: 'fixed',
      bottom: '2%',
      rigth: '1%',
      zIndex: '+1',
    },
  }));

const Protocol = ({match}) => {
    const {folder: folderName, folderId, protocol: protocolName, protocolId} = match.params;
    const initialStateQuestion = {
      "med_id": "",
      "protocol": protocolName,
      "protocol_id": protocolId,
      "active": true,
      "extra_conditions": [],
      "multiple_answers": false,
      "procedure": "",
      "text": "",
      "comment": "",
      "photo": "",
      "trigger_action": {
        "type": ""
      },
      "version": ""
    };
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState(initialStateQuestion);
    const [tags, setTags] = useState([])
    const [protocol, setProtocol] = useState({})
    const [openDialog, setOpenDialog] = useState(false)

    const handleClickOpen = () => {
      setOpenDialog(true);
    };
  
    const handleClose = () => {
      setOpenDialog(false);
    };
    
    const handleDelete = (folderId, protocolId) => {
      const flag = window.confirm("Desea eliminar el protocolo para siempre?")
      if (flag) {
        deleteProtocol(folderId, protocolId).then(({data}) => {
          if (true) {
            alert('Protocolo eliminado con éxito.');
            window.location.href = "/health"
          }
        }).catch(
          (e) => {
            alert('Hubo un problema al eliminar el protocolo.');
            console.log(e)
          }
        );
      }
    }

    const handleTags = async () => {
      if (tags.length){
        try {
          const response = await updateProtocol(folderId, {...protocol, tags: [...tags]})
          alert('Carga de tags: '+response.statusText)
          window.location.reload()
        } catch (error) {
          alert('Error al modificar los tags')
        }
      }
    }

    useEffect(() => {
        let isCancelled = false;
        if (protocolId) {
          setQuestion(initialStateQuestion)
          if (validateSession()){
            getProtocols(folderId, protocolId).then(({data, tags, protocol}) => {
            if (!isCancelled){
              setQuestions([...data])
              tags ? setTags([...tags]):setTags([])
              setProtocol(protocol)
            }
          }).catch(
            (e) => {
              alert('Hubo un problema al intentar traer los protocolos.');
              console.log(e)
            }
          );
        }
        }
        return () => {
          isCancelled = true;
        }
      }, [protocolId, folderId])

    const classes = useStyles();

    return (
        <>
            {!validateSession() ? <Redirect to="/login" /> : ''}
            <Navbar/>
            {folderName || protocolName ? <Grid container className={classes.tags}>
              <Fab color="primary" className={classes.fab} onClick={handleClickOpen} >
                <HelpOutlineIcon />
              </Fab>
              <Fab color="secondary" className={classes.fabDelete} onClick={() => handleDelete(folderId, protocolId)} >
                <DeleteForever />
              </Fab>
              <DialogHelper open={openDialog} handleClose={handleClose} />
              <Grid item xs={12}>
                <h1>Modificar los tags del protocolo: {protocolName}</h1>
              </Grid>
              <Grid item xs={8}>
                <Tags tags={tags} setTags={setTags} />
              </Grid>
              <Grid item xs={4}  className={classes.upload} >
                <Button variant="contained" onClick={handleTags}>Modificar tags</Button>
              </Grid>
            </Grid> : ''}
            {folderName || protocolName ? <Grid container className={classes.main}>
              <Grid item xs={(questions.length === 0) ? 8:12}>
                <h1>Carga de preguntas para el protocolo: {protocolName} (Folder: {folderName})</h1>
              </Grid>
              {(questions.length === 0) ? <Grid item xs={4}  className={classes.upload} >
                <FileUpload setQuestions={setQuestions} folderId={folderId} protocolId={protocolId} />
              </Grid> : ''}
              <Grid item xs={12}>
                <QuestionTable folderId={folderId} questions={questions} setQuestions={setQuestions} setQuestion={setQuestion} ></QuestionTable>    
              </Grid>
              <Grid item xs={12}>
                <UserForm folderId={folderId} protocolId={protocolId} protocol={protocolName} questions={questions} question={question} setQuestion={setQuestion} setQuestions={setQuestions} tags={tags}></UserForm>
              </Grid>
            </Grid> : ''}
        </>
    )
}

export default Protocol
