import React from 'react'
import {
  TextField
} from '@material-ui/core';

const PharmacologicalRecommendations = ({pharmacologicalRecommendations, handleChange}) => {
  return (
    <TextField  label="Recomendaciones Farmacologicas Abreviadas"
      variant="outlined"
      name={'pharmacological_recommendations'}
      multiline
      fullWidth
      margin={'normal'}
      value={pharmacologicalRecommendations || ''}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default PharmacologicalRecommendations
