import React from 'react'
import {
  TextField
} from '@material-ui/core';

const Tracing = ({tracing, handleChange}) => {
  return (
    <TextField  label="Seguimiento"
      variant="outlined"
      name={'tracing'}
      fullWidth
      margin={'normal'}
      value={tracing || ''}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default Tracing
