import axios from 'axios';

export const deleteQuestion = async (folderId, question) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/protocols/${question.protocol_id}/${question.procedure}/${question.id}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.delete(url,{headers: headers});
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}


