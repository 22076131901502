import React from 'react'
import {
  TextField
} from '@material-ui/core';

const DBQuery = ({dbQuery, handleChange}) => {
  return (
    <TextField  label="DB Query"
      variant="outlined"
      name={'db_query'}
      multiline
      fullWidth
      margin={'normal'}
      value={dbQuery}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default DBQuery
