import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import { healthCheckExport } from '../../services/healthCheckExport';
import { getTenants } from '../../services/tenants/getTenants';

const HealthCheckTable = () => {
  const [data, setData] = useState([])
  const [tenants, setTenants] = useState([])

  const fetchHealthCheck = async () => {
    try {
      const response = await healthCheckExport();
      setData(response.data)
    } catch (error) {
      alert('Error al traer los datos del bot')
    }
  }

  const fetchTenants = async () => {
    try {
      const {data} = await getTenants();
      setTenants(data)
    } catch (error) {
      alert('Error al traer los tenants')
    }
  }

  useEffect(() => {
    fetchHealthCheck()
    fetchTenants()
    return () => {
    }
  }, [])

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "ID", field: "id", hidden: true},
          { 
            title: "Tenant", 
            field: "tenant_id",
            render: rowData => {
              let filterTenant = tenants.filter(e => e.id === rowData.tenant_id)
              return filterTenant.length ? filterTenant[0].name : rowData.tenant_id
            }
          },
          { title: "Instancia", field: "instance"},
          { title: "Status", field: "status"},
          { title: "Version", field: "version"},
          { title: "Cantidad de Preguntas", field: "quantity"},
          { title: "Cantidad Actualizadas", field: "quantity_updated"},
          { 
            title: "Fecha de modificación",
            field: "modified_date",
            render: rowData => {
              var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' };
              return new Date(rowData.modified_date * 1000).toLocaleDateString('es-ES', options)
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 25,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          paging: true,
          showTitle: false,
          addRowPosition: "first",
        }}
        data={data}
      />
    </div>
  );
}

export default HealthCheckTable