import axios from 'axios';

export const getFolders = async () => {
  const url = `${process.env.REACT_APP_API_URL}/folders`
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  try {
    const response = await axios.get(url,{headers: headers});
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}


