import React from 'react'
import {
  TextField
} from '@material-ui/core';

const DBOrderBy = ({dbOrderBy, handleChange}) => {
  return (
    <TextField  label="DB Order By"
      variant="outlined"
      name={'db_order_by'}
      multiline
      fullWidth
      margin={'normal'}
      value={dbOrderBy}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default DBOrderBy
