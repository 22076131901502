import React from 'react'
import {
  TextField
} from '@material-ui/core';

const ConditionOp = ({conditionOp, handleChange}) => {
  return (
    <TextField  label="Condition Operador"
      variant="outlined"                            
      fullWidth
      name='condition_op'
      margin={'normal'}
      value={conditionOp}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default ConditionOp
