import React from 'react'
import {
  MenuItem, FormControl, Select
} from '@material-ui/core';

const SelectEnvironment = ({environment, setEnvironment}) => {

  const handleChange = ({value}) => {
    setEnvironment(value)
  }

  return (
    <FormControl style={{minWidth: 180}}>
      <Select
        fullWidth
        displayEmpty
        style={{marginLeft: 'auto'}}
        value={environment}
        onChange={({target}) => {
          handleChange(target); 
        }}
        name='SelectEnvironment'
      >
      <MenuItem value="">
          <em>Entorno</em>
      </MenuItem>
      <MenuItem value={'DEV'}>Dev</MenuItem>
      <MenuItem value={'STAGING'}>Staging</MenuItem>
      <MenuItem value={'PROD'}>Prod</MenuItem>
      </Select>
    </FormControl>
  )
}

export default SelectEnvironment
