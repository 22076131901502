import React from 'react'
import {
  TextField
} from '@material-ui/core';

const AttachPDF = ({attachPdf, handleChange}) => {
  return (
    <TextField  label="Leyenda Dx PDF"
      variant="outlined"
      name="attach_pdf"
      fullWidth
      margin={'normal'}
      value={attachPdf}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default AttachPDF
