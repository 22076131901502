import React from 'react'
import {
  Select, MenuItem
} from '@material-ui/core';

const FormulaHelper = ({handleChange, index}) => {
  return (
    <>
      <Select
        variant='outlined'
        fullWidth
        displayEmpty
        value={''}
        name='condition_formula'
        onChange={({target}) => {
          handleChange(index, target)
        }}
      >
        <MenuItem value={''}>Seleccionar...</MenuItem>
        <MenuItem value={'edad(user)'}>edad(user)</MenuItem>
        <MenuItem value={'es_hombre(user)'}>es_hombre(user)</MenuItem>
        <MenuItem value={'es_mujer(user)'}>es_mujer(user)</MenuItem>
        <MenuItem value={'INMUNOCOMPROMETIDO == True'}>INMUNOCOMPROMETIDO == True</MenuItem>
        <MenuItem value={'COVID19 == True'}>COVID19 == True</MenuItem>
        <MenuItem value={'contesto_si(...)'}>contesto_si(...)</MenuItem>
        <MenuItem value={'puntaje.get("...")'}>puntaje.get("...")</MenuItem>
      </Select>
    </>
  )
}

export default FormulaHelper
