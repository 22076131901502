import React from 'react'
import { Grid } from '@material-ui/core'
import Explanation from './Explanation'
import PharmacologicalRecommendations from './PharmacologicalRecommendations'
import NonPharmacologicalRecommendations from './NonPharmacologicalRecommendations'
import AlarmGuidelines from './AlarmGuidelines'
import Tracing from './Tracing'
import FormatResolution from './FormatResolution'
import Footer from './Footer'
import AttachPDF from './AttachPDF'
import PharmacologicalRecommendationsPDF from './PharmacologicalRecommendationsPDF'
import PharmacologicalRecommendationsIMG from './PharmacologicalRecommendationsIMG'
import NonPharmacologicalRecommendationsIMG from './NonPharmacologicalRecommendationsIMG'
import NonPharmacologicalRecommendationsPDF from './NonPharmacologicalRecommendationsPDF'
import Referral from './Referral'

const ContainerResolution = ({question, handleChange, setQuestion}) => {
  return (
    <Grid container>
      <Grid item xs={12} style={{float: 'right'}}>
        <FormatResolution question={question} setQuestion={setQuestion} />
      </Grid>
      <Grid item xs={12}>
        <Explanation explanation={question.explanation} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <AttachPDF attachPdf={question.attach_pdf} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <Referral referralTo={question.referral_to} referralToSpeciality={question.referral_to_speciality} referralToSpecialitySecond={question.referral_to_speciality_second} referralToType={question.referral_to_type} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <PharmacologicalRecommendations pharmacologicalRecommendations={question.pharmacological_recommendations} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <PharmacologicalRecommendationsPDF pharmacologicalRecommendationsPDF={question.pharmacological_recommendations_pdf} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <PharmacologicalRecommendationsIMG pharmacologicalRecommendationsIMG={question.pharmacological_recommendations_img} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <NonPharmacologicalRecommendations nonPharmacologicalRecommendations={question.non_pharmacological_recommendations} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <NonPharmacologicalRecommendationsPDF nonPharmacologicalRecommendationsPDF={question.non_pharmacological_recommendations_pdf} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <NonPharmacologicalRecommendationsIMG nonPharmacologicalRecommendationsIMG={question.non_pharmacological_recommendations_img} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <AlarmGuidelines alarmGuidelines={question.alarm_guidelines} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <Tracing tracing={question.tracing} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <Footer footer={question.footer} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default ContainerResolution
