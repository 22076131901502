import React,{useState} from 'react';
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {TextField, Button, Grid, Paper, CircularProgress} from '@material-ui/core'
import Navbar from '../components/Navbar/Navbar'
import { validateSession } from '../helpers'
import { changePassword } from '../services/users/changePassword';


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
    marginTop: '10%',
    minHeight: '100vh',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function ChangePassword({redirectUrl}) {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleChangePassword = async () => {
    setLoading(true)
    if (password && newPassword && confirmPassword){
      if (newPassword === confirmPassword){
        try {
          const response = await changePassword({'password':password, 'newPassword': newPassword, 'confirmPassword':confirmPassword})
          if (response.status === 200) {
            alert('La contraseña fue cambiada con exito')
            setPassword('')
            setNewPassword('')
            setConfirmPassword('')
            setLoading(false)
          } else{
            throw new Error()
          }
        } catch (error) {
          alert('Hubo un problema al intentar cambiar su contraseña.')
          setNewPassword('')
          setConfirmPassword('')
          setLoading(false)
        }
      } else{
        alert('Las contraseñas no coinciden')
        setNewPassword('')
        setConfirmPassword('')
        setLoading(false)
      }
    } else{
      alert('Ingrese todos los datos')
      setLoading(false)
    }
  }

  const classes = useStyles();

  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <TextField name="password" label="Contraseña" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={password} onChange={({target}) => setPassword(target.value)} onKeyPress={async (e) => { if (e.key === 'Enter'){await handleChangePassword()}}}/>
            </Grid>
            <Grid item xs={12}>
              <TextField name="newPassword" label="Nueva Contraseña" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={newPassword} onChange={({target}) => setNewPassword(target.value)} onKeyPress={async (e) => { if (e.key === 'Enter'){await handleChangePassword()}}}/>
            </Grid>
            <Grid item xs={12}>
              <TextField name="confirmPassword" label="Confirmar Contraseña" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={confirmPassword} onChange={({target}) => setConfirmPassword(target.value)} onKeyPress={async (e) => { if (e.key === 'Enter'){await handleChangePassword()}}}/>
            </Grid>
            <Grid item xs={12}>
              {loading && <CircularProgress style={{marginTop: '12px', float:'right'}} />}
              {!loading && <Button variant="contained" color="primary" style={{marginTop: '12px', float:'right'}} onClick={async () => { await handleChangePassword() }}>
                CAMBIAR CONTRASEÑA
              </Button>}
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  );
}
