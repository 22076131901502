import React from 'react'
import {
  TextField
} from '@material-ui/core';

const DBName = ({dbName, handleChange}) => {
  return (
    <TextField  label="DB Name"
      variant="outlined"
      name={'db_name'}
      multiline
      fullWidth
      margin={'normal'}
      value={dbName}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default DBName
