import React, { memo, useState } from 'react';
import MaterialTable from '@material-table/core';
import { deleteQuestion } from '../../services/deleteQuestion';
import { updateQuestion } from '../../services/updateQuestion';
import { TextField, Button } from '@material-ui/core';
import titlesDictionary from '../../dictionaries/derivation/titles.json'
import { Edit } from '@material-ui/icons';

const QuestionsTable = memo(({questions, setQuestions, setQuestion, folderId}) => {
  const lookupType = { choice: 'choice', window: 'window', number: 'number', string: 'string', image: 'image', date: 'date', formula: 'formula', database: 'database'}
  const lookupProcedure = {SQ: 'SQ', P: 'P', R:'R'}

  let [columns] = useState([
    { title: "Order",
      field: "order",
      width: "1%",
      filtering: false, 
      editComponent: (props) => (
        <TextField 
          value={props.value} 
          onChange={(event) =>
            event.target.value < 0
                ? alert('El orden no puede ser menor a 0')
                : props.onChange(event.target.value)
        }
          type="number"
        />),
    },
    { title: "MED ID", field: "med_id", filtering: false, width: "9%" },
    { title: "Procedure", field: "procedure", hidden: true, lookup: lookupProcedure, editable: 'never', width: 0},
    { title: "Tipo de pregunta", field: "trigger_action.type", lookup: lookupType, editable: 'never', width: "5%"},
    { title: "Pregunta", field: "text", filtering: false, editable: 'never', width: "50%"},
    { title: "Value Of", field: "trigger_action.value_of", filtering: false, hidden: true, width: 0},
    { title: "Comentario", field: "comment", filtering: false, editable: 'never', hidden: true, width: 0},
    { title: "Imagen", field: "photo", width: "20%"},
    { title: "Derivacion", 
      field: "referral_to",
      editable: 'never',
      filtering: false,
      render: rowData => (
        titlesDictionary.find(e => e.description === rowData.referral_to) ? <span>{titlesDictionary.find(e => e.description === rowData.referral_to)?.id} - {titlesDictionary.find(e => e.description === rowData.referral_to)?.title}</span> : <span></span>
    ),
      width: "20%"},
    ]);


  const handleClickEdit = (index) => {
    setQuestion(questions[index])
    window.scrollTo(0,document.body.scrollHeight)
  }
  
  const handleClickDelete = async (index) => {
    let flag = window.confirm("¿Desea eliminar la pregunta?");
    let flag2 = window.confirm("¿Seguro?");
    if (flag && flag2){
        try {
          const response = await deleteQuestion(folderId, questions[index])
          if (response.status !== 200){
            throw new Error('Error en la api')
          } else{
            alert('Pregunta eliminada con exito')
            window.location.reload()
          }
        } catch (error) {
          alert('Hubo un error al eliminar la pregunta')
        }
      }
    }
    

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        options={{
          actionsColumnIndex: -1,
          pageSize: 500,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          filtering: true,
          paging: true,
          columnsButton: true,
          showTitle: false,
          sorting: true,
          selection: true,
        }}
        columns={columns}
        detailPanel={[
          {
            tooltip: 'Mostrar trigger action',
            render: rowData => {
              return (
                <table border="1">
                  <tbody>
                    <tr>
                      <th>Propiedad</th>
                      <th>Valor</th>
                    </tr>
                    {rowData.trigger_action ? Object.entries(rowData.trigger_action).map((key, index) => (
                      <tr key={index}>
                        <td width="20%">{JSON.stringify(key).slice(1, -1).split(',')[0].slice(1, -1)}</td>
                        <td width="80%">{JSON.stringify(key).slice(1, -1).split(/,(.+)/)[1].slice(1, -1)}</td>
                      </tr>
                    )) : ''}
                  </tbody>
                </table>
              )
            },
          },
        ]}      
        data={questions}
        editable={{
          onRowUpdate: (newData, oldData) =>
            updateQuestion(folderId, newData).then((result) => {
              let aux_questions = questions.slice()
              let objIndex = aux_questions.findIndex((obj => obj.id === newData.id))
              aux_questions[objIndex] = result
              setQuestions(aux_questions);
              alert('Pregunta modificada con exito!')
            }).catch((err) => {
              console.log(err)
            })
          }}
        actions={[
          {
            icon: 'launch',
            tooltip: 'Editar Pregunta',
            onClick: (event, rowData) => {
              let index = questions.findIndex((obj => obj.id === rowData.id))
              handleClickEdit(index)
            },
            position: 'row',
          },
          {
            icon: 'delete',
            tooltip: 'Eliminar Pregunta',
            onClick: (event, rowData) => {
              let index = questions.findIndex((obj => obj.id === rowData.id))
              handleClickDelete(index)
            },
            position: 'row',
          },
          {
            icon: () => <Button
              color="primary"
              variant="contained"
              style={{textTransform: 'none'}}
              size="small"
            >
              CAMBIAR A RESOLUCION
            </Button>,
            tooltip: 'Cambiar a Resolucion',
            onClick: (event, rowData) => {
              var itemsProcessed = 0
              if (!rowData.some(element => element.protocol)) {
                alert('Esta función esta solo disponible cuando todas las preguntas seleccionadas son del tipo resolución')
              } else {
                if(window.confirm(`Esta por transformar en resolucion las siguientes preguntas: \n${rowData.map(element => element.med_id).join('\n')}`)){
                  for (let index = 0; index < rowData.length; index++) {
                    const element = rowData[index];
                    (async () => {
                      try {
                        let prevMedId = element.med_id.substring(0, element.med_id.lastIndexOf('_') + 1);
                        const response = await updateQuestion(folderId, {...element, procedure: 'R', med_id: `${prevMedId}R`})
                      } catch (error) {
                        alert(`Hubo un error al modificar la pregunta: ${element.med_id}`)
                      }
                      itemsProcessed ++
                      if(itemsProcessed === rowData.length) {
                        alert('Por favor refresque la pagina para mostrar las nuevas preguntas');
                      }
                    })();
                  }
                }
              }
            },
          }
        ]}
        icons={{
          Edit: () => <Edit/>
        }}
      />
    </div>
  );
})

export default QuestionsTable