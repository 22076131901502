import React,{useState} from 'react'

import {
  InputLabel, MenuItem, FormControl, Select, Checkbox, Input, ListItemText
} from '@material-ui/core';

const Tags = ({tags, setTags}) => {
  const [options, setOptions] = useState(['INMUNOCOMPROMETIDO', 'ZOONOSIS', 'ETS', 'REUMATO', 'VASCULAR', 'GINECO', 'DIATESISHEMO', 'DEPREANSIE', 'ALIMENTACION', 'COLON', 'EPWORTH', 'FIEBRECUIDADOSDESALUD', 'COVID19'])

  const handleChange = ({value}) => {
    setTags(value)
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Tags</InputLabel>
      <Select
        multiple
        fullWidth
        name='tags'
        value={tags}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
        onChange={({target}) => handleChange(target)}
      >
      {options.map((tag, index) => (
        <MenuItem key={index} value={tag}>
          <Checkbox checked={tags.indexOf(tag) > -1} />
          <ListItemText primary={tag} />
        </MenuItem>
      ))}
      </Select>
    </FormControl>
  )
}

export default Tags
