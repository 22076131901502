const mammoth = require("mammoth");
const questionsRegex = /\[((.|\n)*?)\]/gi;
const deleteRegex = /\{(.*?)\}/gi;

async function extract(arrayBuffer) {
    const text = await mammoth.extractRawText({arrayBuffer: arrayBuffer})
    .then(result => result.value)
    let textWithLineBreaks = text.replace(/(\r\n|\n|\r|\n\n|\r\r)+/gm, '\\n').replace(/(“|”|")/gm,'\\"')
    return textWithLineBreaks.match(questionsRegex)
}

const cleanQuestions = async (arrayBuffer) => {
    let questions = await extract(arrayBuffer)
    return questions.map((question) => {
      return question
    })
}

const transformToJson = (questions) => {
  let questionsArray = questions.map((question) => {
      return JSON.parse('{"text":"'+question.slice(1, -1).trim().replaceAll(deleteRegex, "")+'", "procedure": "SQ", "version": "v0"}')
  })
  return questionsArray
}

export const transform = async (arrayBuffer) => {
  const questions = await cleanQuestions(arrayBuffer)
  const formatQuestionsJson = transformToJson(questions)
  return formatQuestionsJson
}
