import React, {useEffect, useState} from 'react'
import { Dialog, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTenants } from '../../services/tenants/getTenants'
import SelectTenant from '../Tenants/SelectTenant';
import { newFolder } from '../../services/folders/newFolder';
import { addProtocol } from '../../services/addProtocol';
import { newQuestion } from '../../services/newQuestion';

const useStyles = makeStyles(() => ({
  dialog: {
    height: 'auto',
    width: '50%',
    padding: '3%'
  },
}));

const CloneQuestionDialog = ({open, handleClose, folder, folders, protocol, question}) => {
  const classes = useStyles();

  const [tenants, setTenants] = useState([])
  const [selectedTenant, setSelectedTenant] = useState('')

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants();
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchTenants()
    return () => {
      
    }
  }, [])

  const handleCancel = () => {
    setSelectedTenant('')
    handleClose()
  }

  const handleClick = async () => {
    const existFolder = folders.find(e => e.name === folder.name && e.assigned_tenant === selectedTenant)
    const existProtocol = existFolder?.protocols.find(e => e.name === protocol.name)
    const {id, ...clonedQuestion} = question;

    if (clonedQuestion.extra_conditions_original){
      clonedQuestion.extra_conditions = clonedQuestion.extra_conditions_original
      delete clonedQuestion.extra_conditions.original
    }
    
    if (existFolder) {
      if (existProtocol) {
        await newQuestion(existFolder.id, existProtocol.id, clonedQuestion)
        alert('La pregunta se clono con éxito')
        window.location.reload()
      } else {
        const clonedNewProtocol = await addProtocol(protocol.name, existFolder)
        await newQuestion(existFolder.id, clonedNewProtocol.data.id, clonedQuestion)
        alert('La pregunta se clono con éxito')
        window.location.reload()
      }
    } else {
      const clonedNewFolder = await newFolder({name: folder.name, assigned_tenant: selectedTenant})
      const clonedNewProtocol = await addProtocol(protocol.name, clonedNewFolder.data)
      await newQuestion(clonedNewFolder.data.id, clonedNewProtocol.data.id, clonedQuestion)
      alert('La pregunta se clono con éxito')
      window.location.reload()
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth="lg"
      classes={{
        paperWidthLg: classes.dialog,
      }}
    >
      <div>Question: {question.med_id}</div>
      <div>Protocol: {protocol.name}</div>
      <div>Folder: {folder.name}</div>
      <div>Tenant: {tenants.find(e => e.id === selectedTenant)?.name}</div>
      <SelectTenant tenants={tenants} value={selectedTenant} onChange={(event) => setSelectedTenant(event.target.value)} />
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          CANCELAR
        </Button>
        <Button onClick={handleClick} color="primary">
          CLONAR
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloneQuestionDialog
