import React from 'react'
import {
  Grid, Button, Divider
} from '@material-ui/core';
import Type from '../ExtraConditionsControls/Type';
import ConditionFormula from '../ExtraConditionsControls/ConditionFormula';
import ConditionTrue from '../ExtraConditionsControls/ConditionTrue';
import ConditionFalse from '../ExtraConditionsControls/ConditionFalse';
import AnswersTable from '../ExtraConditionsControls/AnswersTable';
import FormulaHelper from '../ExtraConditionsControls/FormulaHelper';

const ContainerExtraConditions = ({question, setQuestion}) => {
  const handleChange = (index,{name, value}) => {
    setQuestion(prevState => {
      let question = Object.assign({}, prevState);
      question.extra_conditions[index][name] = value;   
      return { ...question }; 
    });
  }
  const handleButton = () => {
    setQuestion(prevState => {
      let question = Object.assign({}, prevState);
      question.extra_conditions.push({'type': ''});
      return { ...question }; 
    });
  }
  return (
    <>
      {question.extra_conditions.map((conditions, index) => (
        <Grid container key={index}>
          <Grid item xs={12}>
            <h4>condition_{index+2}:</h4>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Type index={index} type={conditions.type} setQuestion={setQuestion} question={question} />
            </Grid>
          </Grid>
          {conditions.type === 'formula' && <Grid container>
          <Grid item xs={12}>
              <FormulaHelper index={index} handleChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <ConditionFormula index={index} conditionFormula={conditions.condition_formula} handleChange={handleChange} />
            </Grid>
            <Grid item xs={4}>
              <ConditionTrue index={index} conditionTrue={conditions.condition_true} handleChange={handleChange} />
            </Grid>
            <Grid item xs={4}>
              <ConditionFalse index={index} conditionFalse={conditions.condition_false} handleChange={handleChange} />
            </Grid>
          </Grid>}
          {conditions.type === 'choice' && <Grid container>
            <AnswersTable index={index} question={question} setQuestion={setQuestion} />
          </Grid>}
          <Divider style={{width:'100%', margin: '2% 0%'}} />
        </Grid>
      ))}
      <Button variant="contained"
        style={{float: 'right', marginTop: '1%'}}
        onClick={handleButton}
        align={'right'}
        color="primary">
        Agregar condicion extra
      </Button>
    </>
  )
}

export default ContainerExtraConditions
