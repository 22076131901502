import React, {useState} from 'react'
import {Button, CircularProgress} from '@material-ui/core';
import { transform } from '../../../services/transformToJson';
import {newQuestion} from '../../../services/newQuestion';

const FileUpload = ({setQuestions, protocolId, folderId}) => {
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    try {
      const files = e.target.files || [];
      if (!files.length) return;
      const file = files[0];
      setLoading(true)
      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        transform(arrayBuffer).then((e) => {
            (async () => {
              for (const element of e) {
                const data = await newQuestion(folderId, protocolId, element);
                setQuestions(prevState => [...prevState, data])
              }
              alert('Carga completa')
              window.location.reload()
            })()
        })
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      setLoading(false)
    }
    
    
  }
  return (
    <>
      {!loading && <Button
        variant="contained"
        component="label"
      >
        Cargar protocolo inicial
        <input
          type="file"
          accept=".docx"
          hidden
          onChange={(e) => handleChange(e)}
        />
      </Button>}
      {loading && <CircularProgress />}
    </>
  )
}

export default FileUpload
