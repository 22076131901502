import React from 'react'
import {
  TextField
} from '@material-ui/core';

const TemplateName = ({templateName, handleChange}) => {
  return (
    <TextField  label="Template Name"
      variant="outlined"
      name={"template_name"}
      fullWidth
      margin={'normal'}
      value={templateName || ''}
      multiline
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default TemplateName
