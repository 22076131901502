import React from 'react'
import {
  TextField
} from '@material-ui/core';

const AlarmGuidelines = ({alarmGuidelines, handleChange}) => {
  return (
    <TextField  label="Pautas de alarma"
      variant="outlined"
      name={'alarm_guidelines'}
      multiline
      fullWidth
      margin={'normal'}
      value={alarmGuidelines || ''}
      onChange={({target}) => handleChange(target)}
      type="text" />
  )
}

export default AlarmGuidelines
