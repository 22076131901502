import axios from 'axios';

export const updateProtocol = async (folderId, protocol) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/protocols/${protocol.id}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.put(url,{...protocol},{headers: headers});    
    return response
  } catch (error) {
    const {response} = error;
    return response;
  }
}


