import axios from 'axios'
import jwt_decode from 'jwt-decode'

export const login = async (body) => {
  const url = `${process.env.REACT_APP_API_URL}/login`
  try {
    const response = await axios.post(url,{...body});
    const {profile, user_id, user_tenant} = jwt_decode(response.data.access_token)
    response.data.profile = profile
    response.data.user_id = user_id
    response.data.user_tenant = user_tenant
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}

