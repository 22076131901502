import React,{useState, useEffect} from 'react';
import MaterialTable from '@material-table/core';
import { HexColorPicker } from "react-colorful";
import { getTenants } from '../../services/tenants/getTenants';
import { createTenant } from '../../services/tenants/createTenant';
import { updateTenant } from '../../services/tenants/updateTenant';
import { deleteTenant } from '../../services/tenants/deleteTenant';

export default function TenantsTable() {
  const [tenants, setTenants] = useState([])

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants();
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchTenants()
    return () => {
      
    }
  }, [])

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "ID", field: "id", editable: false},
          { title: "Nombre", field: "name"},
          { 
            title: "Color", 
            field: "colour",
            render: rowData => {
              return rowData.colour ? <span style={{userSelect: 'none', pointerEvents: 'none', border: '1px solid black', borderRadius: '4px', background: `${rowData.colour}4d`, color: 'transparent'}}>aca va el color</span> : <span>sin color</span>
            },
            editComponent: (props) => (
              <HexColorPicker 
                color={props.value}
                onChange={(color) => {
                  props.onChange(color)
                }
              }
              />),
          },
        ]}
        editable={{
          onRowAdd: newData =>
            createTenant(newData).then((response) => {
              setTenants([...tenants, response.data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar crear un nuevo tenant.');
                console.log(e)
              }
            ),
          onRowUpdate: (newData, oldData) =>
            updateTenant(newData).then(({data}) => {
              let newTenants = tenants.filter(obj => obj.id !== data.id)
              setTenants([...newTenants, data])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar editar un tenant.');
                console.log(e)
              }
            ),
          onRowDelete: oldData =>
            deleteTenant(oldData).then((data) => {
              let newTenants = tenants.filter(obj => obj.id !== oldData.id)
              setTenants([...newTenants])
            }).catch(
              (e) => {
                alert('Hubo un problema al intentar borrar un tenant.');
                console.log(e)
              }
            ),
          }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          paging: true,
          showTitle: false,
          addRowPosition: "first",
        }}
        data={tenants}
      />
    </div>
  );
}
