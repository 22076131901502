import React, {useState, memo} from 'react'
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Collapse, CircularProgress} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NestedListQuestions from './NestedListQuestions';
import { deleteProtocol } from '../../services/deleteProtocol';
import FileCopy from '@material-ui/icons/FileCopy';
import CloneProtocolDialog from './CloneProtocolDialog';

const useStyles = makeStyles((theme) => ({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

const NestedList = memo(({folders, folder, protocols, setFolders, setOpen}) => {
  const [expandIndex, setExpandIndex] = useState('false')
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [protocolToClone, setProtocolToClone] = useState()

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleClick = (protocol) => {
    setProtocolToClone(protocol)
    setOpenDialog(true)
  }
  
  const handleExpand = index => {
    setLoading(true)
    if (expandIndex === index) {
      setExpandIndex('')
    } else {
      setExpandIndex(index)
    }
  }

  // const handleClick = (folderId, protocolId) => {
  //   const flag = window.confirm("Desea eliminar el protocolo para siempre?")
  //   if (flag) {
  //     let auxFolder = {...folder}
  //     deleteProtocol(folderId, protocolId).then(({data}) => {
  //       if(data){
  //         let newProtocols = auxFolder.protocols.filter(obj => obj.id !== protocolId)
  //         auxFolder.protocols = newProtocols
  //         setFolders(prevState => prevState.map((e) => {
  //           return e.id === auxFolder.id ? auxFolder : e
  //         }))
  //       }
  //     }).catch(
  //       (e) => {
  //         alert('Hubo un problema al eliminar el protocolo.');
  //         console.log(e)
  //       }
  //     );
  //   }
  // }

    const classes = useStyles();
    return (
        <>
          {protocolToClone && <CloneProtocolDialog protocols={protocols} folders={folders} open={openDialog} handleClose={handleClose} protocol={protocolToClone} folder={folder} />}
          <List
            disablePadding={true}
          >
            {protocols.sort((a, b) => a.name.localeCompare(b.name)).map((protocol, index) => (
              <div key={index}>
                <ListItem  style={{background: folder.tenant?.colour ? `${folder.tenant?.colour}4d` : ''}} button className={classes.nested} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/protocols/'+folder.name+'/'+folder.id+'/'+protocol.name+'/'+protocol.id} key={protocol.id}>
                <ListItemIcon style={{minWidth: '40px'}}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary={protocol.name} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleClick(protocol)}>
                    <FileCopy />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleExpand(index)}>
                    {
                      loading ? index === expandIndex ? <CircularProgress size={20} /> : <ExpandMore /> : index === expandIndex ? <ExpandLess /> : <ExpandMore />
                    }
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse in={index === expandIndex} timeout="auto">
                {index === expandIndex && <NestedListQuestions folders={folders} setLoading={setLoading} folder={folder} protocol={protocol} />}
              </Collapse>
            </div>
            ))}
          </List>
        </>
    )
})

export default NestedList
