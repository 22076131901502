import axios from 'axios';

const castToArray = (extraConditions) => {
  let arrayConditions = []
  for (let prop in extraConditions) {
    arrayConditions.push(extraConditions[prop])
  }
  return arrayConditions
}

export const newQuestion = async (folderId, protocolId, question) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/protocols/${protocolId}/${question.procedure}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    // Blank med_id so that API creates new question accordingly.
    question.med_id = ''
    
    const response = await axios.post(url,{...question},{headers: headers});
    return {...response.data, extra_conditions: castToArray(response.data.extra_conditions), procedure: response.data.med_id.substring(response.data.med_id.lastIndexOf('_')+1)};
  } catch (error) {
    const {response} = error;
    return response;
  }
}


