import React,{useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TextField, IconButton, InputLabel} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function PrescriptionScoreTable({prescriptionScore, setQuestion}) {

  const rows = typeof prescriptionScore === 'string' ? {si: parseFloat(prescriptionScore)} : {...prescriptionScore}
  
  const [scoreKey, setScoreKey] = useState('')
  const [scoreValue, setScoreValue] = useState(0)

  const handleDelete = (key) => {
    let { [key]: value, ...rest } = rows
    setQuestion(prevState => {
      let question = Object.assign({}, prevState);
      question.trigger_action.prescription_score = {...rest}
      return { ...question }
    });
  }

  const handleAdd = () => {
    if (scoreKey !== '') {
      rows[scoreKey] = parseFloat(scoreValue)
      setQuestion(prevState => {
        let question = Object.assign({}, prevState);
        question.trigger_action.prescription_score = rows;   
        return { ...question }
      });
      setScoreKey('');
      setScoreValue(0);
    } else{
        alert('Debe completar todos los campos');
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <InputLabel>Puntaje</InputLabel>
      </Grid>
      <Grid item xs={3}>
          <TextField  label="Key"
              name='scoreKey'
              variant="outlined"                            
              fullWidth
              margin={'normal'}
              value={scoreKey}
              onChange={(e) => setScoreKey(e.target.value)}
              type="text" />
      </Grid>
      <Grid item xs={2}>
          <TextField  label="Value"
              name='scoreValue'
              variant="outlined"                            
              fullWidth
              margin={'normal'}
              value={scoreValue}
              onChange={(e) => setScoreValue(e.target.value)}
              inputProps={{
                step: "0.5",
                min: -10,
                max: 10,
              }}
              type="number" />
      </Grid>
      <Grid item xs={1}>
          <IconButton color="primary" style={{marginTop: 15}} onClick={handleAdd}>
              <ForwardIcon fontSize='large' />
          </IconButton>
      </Grid>
      <Grid item xs={6}>
        <TableContainer style={{float: 'left', maxWidth: '50%'}} component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows ? Object.entries(rows).map((key, index) => (
                <TableRow key={index}>
                  <TableCell>{JSON.stringify(key).slice(1, -1).split(',')[0].slice(1, -1)}</TableCell>
                  <TableCell>{JSON.stringify(key).slice(1, -1).split(',')[1]}</TableCell>
                  <TableCell>{<IconButton onClick={() => handleDelete(JSON.stringify(key).slice(1, -1).split(',')[0].slice(1, -1))}><DeleteOutlineIcon /></IconButton>}</TableCell>
                </TableRow>
              )) : ''}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
