import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const DBOp = ({dbOp, handleChange}) => {
  return (
    <FormControl variant="outlined" style={{minWidth: '100%', margin: '16px 0px'}}>
        <InputLabel>DB Op</InputLabel>
        <Select                            
        fullWidth
        name={'db_op'}
        value={dbOp || ''}
        onChange={({target}) => handleChange(target)}
        label="DB Op"
        >
          <MenuItem value={'read_only'}>read_only</MenuItem>
          <MenuItem value={'single_clone'}>single_clone</MenuItem>
          <MenuItem value={'single_delete'}>single_delete</MenuItem>
          <MenuItem value={'single_update'}>single_update</MenuItem>
          <MenuItem value={'list_clone'}>list_clone</MenuItem>
          <MenuItem value={'list_delete'}>list_delete</MenuItem>
          <MenuItem value={'list_update'}>list_update</MenuItem>
          <MenuItem value={'select_one'}>select_one</MenuItem>
          <MenuItem value={'data_selected'}>data_selected</MenuItem>
          <MenuItem value={'text_search'}>Texto Libre</MenuItem>
        </Select>
    </FormControl>
  )
}

export default DBOp
