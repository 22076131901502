import axios from 'axios';

export const updateUser = async (user) => {
  const url = `${process.env.REACT_APP_API_URL}/users/${localStorage.getItem('user_id')}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.patch(url,{...user},{headers: headers});
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}


