import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const Type = ({type, setQuestion, question, index}) => {

  const handleChange = ({value}) => {
    switch (value) {
      case 'choice':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          return { ...question }; 
        });
        break;
      case 'string':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          question.extra_conditions[index].condition_false = question.med_id;
          question.extra_conditions[index].condition_true = '';
          return { ...question }; 
        });
        break;
      case 'image':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          question.extra_conditions[index].condition_false = question.med_id;
          question.extra_conditions[index].condition_true = '';
          return { ...question }; 
        });
        break;
      case 'number':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          question.extra_conditions[index].condition_false = question.med_id;
          question.extra_conditions[index].condition_true = '';
          return { ...question }; 
        });
        break;
        case 'date':
          setQuestion(prevState => {
            let question = Object.assign({}, prevState);
            question.extra_conditions[index] = {'type': value};
            question.extra_conditions[index].condition_false = question.med_id;
            question.extra_conditions[index].condition_true = '';
            question.extra_conditions[index].date = '';
            return { ...question }; 
          });
          break;
      case 'window':
      setQuestion(prevState => {
        let question = Object.assign({}, prevState);
        question.extra_conditions[index] = {'type': value};
        question.extra_conditions[index].window_list = [];
        question.extra_conditions[index].condition_value = '';
        question.extra_conditions[index].condition_op = '';
        question.extra_conditions[index].condition_compare = '';
        return { ...question }; 
      });
      break;
      case 'formula':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          question.extra_conditions[index].condition_formula = '';
          question.extra_conditions[index].condition_true = '';
          question.extra_conditions[index].condition_false = '';
          return { ...question }; 
        });
        break;
      case '':
        setQuestion(prevState => {
          let question = Object.assign({}, prevState);
          question.extra_conditions[index] = {'type': value};
          return { ...question }; 
        });
        break;
      default:
        break;
    }
  }

  return (
    <FormControl variant="outlined" style={{minWidth: 150}}>
      <InputLabel>Type</InputLabel>
      <Select                            
      fullWidth
      value={type}
      onChange={({target}) => {
        handleChange(target); 
      }}
      label="Type"
      name='type'
      >
      <MenuItem value="">
          <em>None</em>
      </MenuItem>
      <MenuItem disabled={true} value={'number'}>Numero</MenuItem>
      <MenuItem disabled={true} value={'string'}>Texto</MenuItem>
      <MenuItem disabled={true} value={'window'}>Window</MenuItem>
      <MenuItem value={'formula'}>Formula</MenuItem>
      <MenuItem value={'choice'}>Choice</MenuItem>
      <MenuItem disabled={true} value={'date'}>Fecha</MenuItem>
      </Select>
    </FormControl>
  )
}

export default Type
