import React,{useState} from 'react';
import  { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {TextField, Button, Grid, Paper, CircularProgress} from '@material-ui/core'
import SelectMultipleTenant from '../components/Tenants/SelectMultipleTenant'
import Navbar from '../components/Navbar/Navbar'
import { createUser } from '../services/users/createUser';
import { validateSession } from '../helpers';


const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    marginLeft: '40%',
    marginTop: '10%',
    minHeight: '100vh',
  },
  paper: {
    minWidth: '40%',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));

export default function CreateUser() {
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [password, setPassword] = useState('')
  const [profile, setProfile] = useState('')
  const [tenants, setTenants] = useState([])


  function handleCreateUser() {
    setLoading(true)
    if (username && email && name && lastname && password && profile && tenants){
      const newUser = {
        'username': username, 
        'email': email, 
        'name': name, 
        'lastname': lastname, 
        'password': password,
        'profile': profile,
        'tenant': tenants
      }
      createUser(newUser).then(({data, status}) => {
        if (status === 200){
          alert('Usuario creado con exito.')
          setLoading(false)
          setUsername('')
          setName('')
          setLastname('')
          setEmail('')
          setPassword('')
          setProfile('')
          setTenants([])
        } else{
          alert('Hubo un problema al intentar crear el usuario.')
          setPassword('')
          setLoading(false)
        }
      }).catch(
        (e) => {
          alert('Hubo un problema al intentar crear el usuario.')
          setPassword('')
          setLoading(false)
        }
      )
    } else{
      alert('Todos los campos son obligatorios')
      setLoading(false)
    }
  }

  const classes = useStyles();

  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <div className={classes.root}>
        <Grid container >
          <Paper className={classes.paper}>
            <Grid item xs={12}>
              <TextField name="username" label="Username" variant="outlined" fullWidth style={{marginTop: '12px'}} autoComplete='off' value={username} onChange={({target}) => setUsername(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="email" label="Email" variant="outlined" type="email" fullWidth style={{marginTop: '12px'}} value={email} onChange={({target}) => setEmail(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="name" label="Nombre" variant="outlined" type="text" fullWidth style={{marginTop: '12px'}} value={name} onChange={({target}) => setName(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="lastname" label="Apellido" variant="outlined" type="text" fullWidth style={{marginTop: '12px'}} value={lastname} onChange={({target}) => setLastname(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="password" label="Password" variant="outlined" type="password" fullWidth style={{marginTop: '12px'}} value={password} onChange={({target}) => setPassword(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="profile" label="Profile" variant="outlined" type="text" fullWidth style={{marginTop: '12px'}} value={profile} onChange={({target}) => setProfile(target.value)} />
            </Grid>
            <Grid item xs={12}>
              <SelectMultipleTenant tenants={tenants} setTenants={setTenants} />
            </Grid>
            <Grid item xs={12}>
              {loading && <CircularProgress style={{float:'right'}} />}
              {!loading && <Button variant="contained" color="primary" style={{float:'right'}} onClick={handleCreateUser}>
                CREAR
              </Button>}
            </Grid>
          </Paper>
        </Grid>
      </div>
    </>
  );
}
