import axios from 'axios';

export const copyFolder = async (folderId, tenantId) => {
  const url = `${process.env.REACT_APP_API_URL}/folders/${folderId}/copy/${tenantId}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(url, {headers: headers});
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}


