import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const MultipleAnswers = ({multipleAnswers, handleChange}) => {
  return (
    <FormControl variant="outlined" style={{float: 'left', minWidth: 150}}>
        <InputLabel>Multiples Preguntas</InputLabel>
        <Select                            
        fullWidth
        name={'multiple_answers'}
        value={multipleAnswers || false}
        onChange={({target}) => handleChange(target)}
        label="Multiples Preguntas"
        >
        <MenuItem value={true}>Si</MenuItem>
        <MenuItem value={false}>No</MenuItem>
        </Select>
    </FormControl>
  )
}

export default MultipleAnswers
