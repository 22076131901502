import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select, Checkbox, Input, ListItemText, Tooltip
} from '@material-ui/core';

const WindowList = ({windowList, handleChange, questions, tags}) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Window List</InputLabel>
      <Select
        multiple
        fullWidth
        name='window_list'
        value={windowList}
        onChange={({target}) => handleChange(target)}
        input={<Input />}
        renderValue={(selected) => selected.join(', ')}
      >
      {questions.map((question) => (
        <MenuItem key={question.med_id} value={question.med_id}>
          <Checkbox checked={windowList.indexOf(question.med_id) > -1} />
          <Tooltip placement="top" interactive arrow title={<div style={{ fontSize: "medium" }}>{question.text}</div>} >
            <ListItemText primary={question.med_id} />
          </Tooltip>
        </MenuItem>
      ))}
      {tags.map((tag, index) => (
        <MenuItem key={tag+index} value={tag}>
          <Checkbox checked={windowList.indexOf(tag) > -1} />
            <ListItemText primary={'TAG: '+tag} />
        </MenuItem>
      ))}
      </Select>
    </FormControl>
  )
}

export default WindowList
