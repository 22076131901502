import React from 'react'
import {
  TextField
} from '@material-ui/core';

const ConditionFalse = ({conditionFalse, handleChange, disabled, index}) => {
  return (
    <TextField  label="Condition False"
      variant="outlined"                            
      fullWidth
      disabled={disabled}
      name='condition_false'
      margin={'normal'}
      value={conditionFalse}
      onChange={({target}) => handleChange(index, target)}
      type="text" />
  )
}

export default ConditionFalse
