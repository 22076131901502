import React,{useState, useEffect} from 'react';
import MaterialTable from '@material-table/core';
import { getFolders } from '../../services/folders/getFolders';
import { newFolder } from '../../services/folders/newFolder';
import { updateFolder } from '../../services/folders/updateFolder';
import { getTenants } from '../../services/tenants/getTenants';
import SelectTenant from '../Tenants/SelectTenant';
import { FileCopy } from '@material-ui/icons';
import TenantDialog from './TenantDialog';

export default function FoldersTable() {
  const [folders, setFolders] = useState([])
  const [tenants, setTenants] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [folderToCopy, setFolderToCopy] = useState('')

  const handleClickOpen = (folderId) => {
    setOpenDialog(true)
    setFolderToCopy(folderId)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  useEffect(() => {
    const fetchFolders = async () => {
      try {
        const {data} = await getFolders();
        setFolders(data)
      } catch (error) {
        alert('Error al traer los folders')
      }
    }
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants()
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchFolders()
    fetchTenants()
    return () => {
      
    }
  }, [])

  return (
    <>
      <TenantDialog folderId={folderToCopy} tenants={tenants} open={openDialog} handleClose={handleClose} />
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[
            { title: "ID", field: "id", hidden: true},
            { title: "Nombre", field: "name"},
            { 
              title: "Tenant", 
              field: "assigned_tenant",
              render: rowData => {
                let filterTenant = tenants.filter(e => e.id === rowData.assigned_tenant)
                return filterTenant.length ? filterTenant[0].name : rowData.assigned_tenant
              },
              editComponent: (props) => (
                <SelectTenant 
                  tenants={tenants}
                  value={props.value}
                  onChange={(event) =>
                    props.onChange(event.target.value)
                }
                />),
            },
          ]}
          editable={{
            onRowAdd: newData =>
              newFolder(newData).then((data) => {
                setFolders([...folders, data])
                window.location.reload()
              }).catch(
                (e) => {
                  alert('Hubo un problema al intentar crear un nuevo folder.');
                  console.log(e)
                }
              ),
            onRowUpdate: (newData, oldData) =>
              updateFolder(newData).then(({data}) => {
                let newTenants = folders.filter(obj => obj.id !== data.id)
                setFolders([...newTenants, data])
                window.location.reload()
              }).catch(
                (e) => {
                  alert('Hubo un problema al intentar editar un folder.');
                  console.log(e)
                }
              ),
          }}
          actions={[
            {
              name: "copy",
              tooltip: 'Copiar carpeta',
              icon: () => <FileCopy />,
              onClick: (evt, data) => {
                (async function() {
                  handleClickOpen(data.id)
                })();
              },
              position: 'row',
              
            }
          ]}
          options={{
            actionsColumnIndex: -1,
            pageSize: 20,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
            paging: true,
            showTitle: false,
            addRowPosition: "first",
          }}
          data={folders}
        />
      </div>
    </>
  );
}
