import axios from 'axios';

export const exportProtocols = async (tenantId, environment) => {
  const url = `${process.env.REACT_APP_API_URL}/export/${tenantId}/${environment}`;
  const headers = {
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  };
  try {
    const response = await axios.get(url,{headers: headers});
    return response;
  } catch (error) {
    const {response} = error;
    return response;
  }
}


