import { getProtocols } from "./getProtocols";
import { updateQuestion } from "./updateQuestion";

export const replaceCharacter = async (folderId, protocolId) => {
  getProtocols(folderId, protocolId).then(({data}) => {
    let questions = [...data];
    let replacedQuestions = questions.map((obj) => {
      /*if (obj.trigger_action.value_of) {
        console.log(obj.trigger_action.value_of)
      }
      const backupValueOf = obj.trigger_action.value_of
      let parsedObj = JSON.stringify(obj).replaceAll("cantidad_de_cigarrillos_dia","value_of.get('cantidad_de_cigarrillos_dia')").replaceAll("años_de_tabaquismo_actual","value_of.get('años_de_tabaquismo_actual')").replaceAll("cantidad_de_cigarrillos_fumados","value_of.get('cantidad_de_cigarrillos_fumados')").replaceAll("años_de_tabaquismo_previo","value_of.get('años_de_tabaquismo_previo')")
      const newObj = JSON.parse(parsedObj)
      newObj.trigger_action.value_of = backupValueOf
      */
      const newObj = {...obj}
      let prevMedId = newObj.med_id.substring(newObj.med_id.indexOf('_') + 7, newObj.med_id.lastIndexOf('_'))
      newObj.order = (parseInt(prevMedId)) * 10
      return newObj
    })
    console.log(replacedQuestions)
    replacedQuestions.map(question => {
      updateQuestion(folderId, question).then((result) => {
        console.log(result)
      }).catch((err) => {
        console.log(err)
      });
    })
    
  }).catch(
    (e) => {
      alert('Hubo un problema al intentar traer las preguntas en el navbar.');
      console.log(e)
    }
  );
}