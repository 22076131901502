import React from 'react'
import {
  Grid
} from '@material-ui/core';
import DBMode from '../TriggerAction/DBMode';
import DBOp from '../TriggerAction/DBOp';
import DBName from '../TriggerAction/DBName';
import DBQuery from '../TriggerAction/DBQuery';
import DBOrderBy from '../TriggerAction/DBOrderBy';
import DBLineTemplate from '../TriggerAction/DBLineTemplate';
import DBUpdate from '../TriggerAction/DBUpdate';
import ContainerChoice from './ContainerChoice';
import DBConfirmExecution from '../TriggerAction/DBConfirmExecution';

const ContainerDatabase = ({question, setQuestion, handleChange}) => {
  return (
    <Grid container>
      <ContainerChoice question={question} setQuestion={setQuestion}/>
      <Grid item xs={4}>
        <DBName dbName={question.trigger_action.db_name} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBMode dbMode={question.trigger_action.db_mode} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBOp dbOp={question.trigger_action.db_op} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBQuery dbQuery={question.trigger_action.db_query} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBOrderBy dbOrderBy={question.trigger_action.db_order_by} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBUpdate dbUpdate={question.trigger_action.db_update} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <DBConfirmExecution dbConfirmExecution={question.trigger_action.db_confirm_execution} handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <DBLineTemplate dbLineTemplate={question.trigger_action.db_line_template} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default ContainerDatabase
