import React from 'react'
import {
  InputLabel, MenuItem, FormControl, Select
} from '@material-ui/core';

const Procedure = ({procedure, handleChange}) => {
  return (
    <FormControl variant="outlined" style={{float: 'right', minWidth: 150}}>
        <InputLabel>Procedure</InputLabel>
        <Select                            
        fullWidth
        name={'procedure'}
        value={procedure}
        onChange={({target}) => handleChange(target)}
        label="Procedure"
        >
        <MenuItem value="">
            <em>None</em>
        </MenuItem>
        <MenuItem value={'SQ'}>Pregunta sintomática</MenuItem>
        <MenuItem value={'P'}>Puntaje</MenuItem>
        <MenuItem value={'R'}>Resolucion</MenuItem>
        </Select>
    </FormControl>
  )
}

export default Procedure
