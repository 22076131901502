import React, { useState, useEffect } from 'react'
import {
  MenuItem, Select, InputLabel, FormControl
} from '@material-ui/core';
import { getTenants } from '../../services/tenants/getTenants';

const SelectMultipleTenant = ({tenants, setTenants}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    async function fetchTenants() {
      const {data} = await getTenants()
      setData(data)
    }

    fetchTenants()
    return () => {
      
    }
  }, [])

  const handleChange = ({value}) => {
    setTenants(value)
  }

  return (
    <FormControl variant="outlined" fullWidth style={{margin: "5% 0"}}>
      <InputLabel>Tenants</InputLabel>
      <Select      
        fullWidth
        multiple
        label="Tenants"
        value={tenants}
        onChange={({target}) => {
          handleChange(target); 
        }}
        name='tenants'
      >
        {
          data.map(e => <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}

export default SelectMultipleTenant
