import React from 'react'
import {
  Grid
} from '@material-ui/core';
import Date from '../TriggerAction/Date';
import ConditionTrue from '../TriggerAction/ConditionTrue';
import ConditionFalse from '../TriggerAction/ConditionFalse';

const ContainerDate = ({question, handleChange}) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Date date={question.trigger_action.date} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionTrue conditionTrue={question.trigger_action.condition_true} handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <ConditionFalse disabled conditionFalse={question.trigger_action.condition_false} handleChange={handleChange} />
      </Grid>
    </Grid>
  )
}

export default ContainerDate
