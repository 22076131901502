import React from 'react'
import {
  TextField, Tooltip
} from '@material-ui/core';

const ValueOf = ({valueOf, handleChange}) => {
  return (
    <Tooltip placement="top" arrow title={<div style={{ fontSize: "medium" }}>{'Recuerde que el valor debe estar en minusculas y sin espacios (use guión bajo ("_") para separar las palabras)'}</div>}>
      <TextField  label="Valor a guardar"
        variant="outlined"                            
        fullWidth
        name='value_of'
        margin={'normal'}
        value={valueOf || ''}
        onChange={({target}) => handleChange(target)}
        type="text" />
    </Tooltip>
  )
}

export default ValueOf
