import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Protocol from './pages/Protocol'
import Login from './pages/Login'
import TagsMap from './pages/TagsMap';
import HealthCheck from './pages/HealthCheck';
import Drugs from './pages/Drugs';
import DrugsClasses from './pages/DrugsClasses';
import CreateUser from './pages/CreateUser';
import ModifyUser from './pages/ModifyUser';
import ChangePassword from './pages/ChangePassword';
import Tenants from './pages/Tenants';
import Folders from './pages/Folders';
import Export from './pages/Export';

const App = () => {
  return (
    <>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" ><Redirect to="/health" /> </Route>
        <Route exact path="/login" render={() => <Login />} />
        <Route exact path="/users/create" render={() => <CreateUser />} />
        <Route exact path="/users/modify" render={() => <ModifyUser />} />
        <Route exact path="/users/changepassword" render={() => <ChangePassword />} />
        <Route exact path="/export/:id" render={(props) => <Export {...props} />} />
        <Route exact path="/folders" render={() => <Folders />} />
        <Route exact path="/tenants" render={() => <Tenants />} />
        <Route exact path="/tags" render={() => <TagsMap />} />
        <Route exact path="/health" render={() => <HealthCheck />} />
        <Route exact path="/protocols" render={(props) => {
                return ( <Protocol {...props } /> )
            }} />
        <Route path="/protocols/:folder/:folderId/:protocol/:protocolId" render={(props) => {
                return ( <Protocol {...props } /> )
            }} />
        <Route exact path="/drugs" render={() => <Drugs />} />
        <Route exact path="/drugs/classes" render={() => <DrugsClasses />} />
      </Switch>
    </BrowserRouter>
    </>
  )
}

export default App