import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from "react-router-dom"
import {List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Divider, Collapse, Tooltip} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import BuildIcon from '@material-ui/icons/Build'
import BallotIcon from '@material-ui/icons/Ballot'
import FolderIcon from '@material-ui/icons/Folder'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import EditIcon from '@material-ui/icons/Edit'
import PersonIcon from '@material-ui/icons/Person'
import TenantsIcon from '@material-ui/icons/DesktopWindows'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import NestedList from './NestedList'
import { addProtocol } from '../../services/addProtocol'
import { deleteFolder } from '../../services/deleteFolder'
import { getTenants } from '../../services/tenants/getTenants'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function isValid(str) {
  return !/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
}


const FolderList = ({folders, setFolders, setOpen}) => {
  const classes = useStyles();
  const [expandIndex, setExpandIndex] = useState('false')
  const [tenants, setTenants] = useState([])

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const {data} = await getTenants();
        setTenants(data)
      } catch (error) {
        alert('Error al traer los tenants')
      }
    }
    fetchTenants()
    return () => {
      
    }
  }, [])
  
  const handleExpand = index => {
    if (expandIndex === index) {
      setExpandIndex('')
    } else {
      setExpandIndex(index)
    }
  }
  
  const handleClickFolder = (folderId) => {
    let protocolToAdd = prompt("Añadir protocolo: ");
    if (protocolToAdd && isValid(protocolToAdd)) {
      let auxFolder = folders.find(element => element.id === folderId);
      addProtocol(protocolToAdd, auxFolder).then(({data}) => {
        let auxFolders = folders.map(element => {
          if (element.id === folderId){
            element.protocols = [...element.protocols, {'name': data.name, 'id':data.id}]
          }
          return element
        });
        setFolders([...auxFolders])
      }).catch(
        (e) => {
          alert('Hubo un problema al crear la nueva carpeta.');
          console.log(e)
        }
      );
    } else {
      alert('El nombre del protocolo no puede estar vacio ni contener caracteres especiales.')
    }
  };

  const handleClickDelete = (folderId) => {
    const flag = window.confirm("Desea eliminar la carpeta para siempre?")
    if (flag){
      const filterFolder = folders.filter(folder => folder.id === folderId)
      if (filterFolder[0].protocols.length > 0){
        alert('Debe eliminar primero todos los protocolos de la carpeta: '+filterFolder[0].name)
      } else{
        deleteFolder(folderId).then(({data}) => {
          if(data){
            let auxFolders = folders.filter(obj => obj.id !== folderId)
            setFolders(auxFolders)
          }
        }).catch(
          (e) => {
            alert('Hubo un problema al eliminar el protocolo.');
            console.log(e)
          }
        );
      }
    }
  };

  
  return (
    <List
      component="nav"
      className={classes.root}
      disablePadding={true}
    >
      <Divider />
      <ListItem button key={'user'} onClick={() => handleExpand('users')}>
      <ListItemIcon style={{minWidth: '40px'}}>
        <AccountBoxIcon />
      </ListItemIcon>
      <ListItemText primary={'Gestion de usuarios'} />
      <ListItemSecondaryAction>
        <IconButton style={{padding: '0px'}} onClick={() => handleExpand('users')}>
            {'users' === expandIndex ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={'users' === expandIndex} timeout="auto">
        <ListItem className={classes.nested} button key={'createUser'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/users/create'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary={'Crear usuario'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'editUser'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/users/modify'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary={'Editar usuario'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'changePassword'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/users/changepassword'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={'Cambiar contraseña'} />
        </ListItem>
      </Collapse>
      <Divider />
      <ListItem button key={'tools'} onClick={() => handleExpand('tools')}>
      <ListItemIcon style={{minWidth: '40px'}}>
        <BuildIcon />
      </ListItemIcon>
      <ListItemText primary={'Tools'} />
      <ListItemSecondaryAction>
        <IconButton style={{padding: '0px'}} onClick={() => handleExpand('tools')}>
            {'tools' === expandIndex ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={'tools' === expandIndex} timeout="auto">
      <ListItem className={classes.nested} button key={'folders'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/folders'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={'Gestion de Folders'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'tenants'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/tenants'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <TenantsIcon />
          </ListItemIcon>
          <ListItemText primary={'Gestion de Tenants'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'tags'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/tags'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <BallotIcon />
          </ListItemIcon>
          <ListItemText primary={'Mapeo de Tags'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'health'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/health'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={'Estado del bot'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'drugs'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/drugs'}>
          <ListItemIcon style={{minWidth: '40px'}}>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary={'Busqueda de drogas'} />
        </ListItem>
        <ListItem className={classes.nested} button key={'drugsClasses'} onClick={() => setOpen(prevState => !prevState)} component={Link} to={'/drugs/classes'}>
          <ListItemIcon style={{minWidth: '40px'}}>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary={'Clasificacion de drogas'} />
      </ListItem>
      </Collapse>
      <Divider />
      <ListItem button key={'export'} onClick={() => handleExpand('export')}>
      <ListItemIcon style={{minWidth: '40px'}}>
        <CloudUploadIcon />
      </ListItemIcon>
      <ListItemText primary={'Export'} />
      <ListItemSecondaryAction>
        <IconButton style={{padding: '0px'}} onClick={() => handleExpand('export')}>
            {'export' === expandIndex ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={'export' === expandIndex} timeout="auto">
        {
          tenants.map((tenant, index) => (
            <ListItem style={{background: `${tenant.colour}4d`}} className={classes.nested} button key={index} onClick={() => setOpen(prevState => !prevState)} component={Link} to={`/export/${tenant.id}`}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText primary={tenant.name} />
            </ListItem>
          ))
        }
        <Divider />
      </Collapse>
      <Divider />

      {folders.map((folder, index) => (
        <div key={index}>
            <ListItem style={{background: folder.tenant?.colour ? `${folder.tenant?.colour}4d` : ''}} button key={folder.id} onClick={() => handleExpand(index)} >
              <ListItemIcon style={{minWidth: '40px'}}>
                <FolderIcon />
              </ListItemIcon>
              <Tooltip placement="top" arrow title={<div style={{ fontSize: "medium" }}>{folder.tenant?.name}</div>} >
                <ListItemText primary={folder.name} style={{maxWidth: '80%'}} />
              </Tooltip>
              <ListItemSecondaryAction>
                <IconButton style={{padding: '0px'}} onClick={() => handleClickFolder(folder.id)}>
                  <AddIcon />
                </IconButton>
                <IconButton style={{padding: '0px'}} onClick={() => handleClickDelete(folder.id)}>
                  <DeleteForeverIcon />
                </IconButton>
                <IconButton style={{padding: '0px'}} onClick={() => handleExpand(index)}>
                    {index === expandIndex ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={index === expandIndex} timeout="auto">
              <NestedList setOpen={setOpen} folders={folders} setFolders={setFolders} protocols={folder.protocols} folder={folder} />
            </Collapse>
            <Divider />
        </div>
      ))}
    </List>
  );
}

export default FolderList