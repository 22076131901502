import React from 'react';
import MaterialTable from '@material-table/core';

export default function DrugsTable({drugs}) {

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Concept ID", field: "concept_id"},
          { title: "Term", field: "term"},
          { title: "Comercial Name", field: "commercial_name"},
          { title: "Components", field: "components"},
          { title: "Presentation", field: "presentation"},
          { title: "Dose", field: "dose"},
          { title: "Cat 1", field: "cat_1"},
          { title: "Cat 2", field: "cat_2"},
          { title: "Clasification", field: "clasification"},
          { title: "Action Category", field: "action_category"},
          { title: "Warning Level", field: "warning_level"},
          { title: "Score", field: "score"},
        ]}
        options={{
          actionsColumnIndex: -1,
          pageSize: 20,
          emptyRowsWhenPaging: false,
          pageSizeOptions: [25, 50, 100, 200, 500, { value: 1000, label: 'Todos' }],
          paging: true,
          showTitle: false,
          addRowPosition: "first",
        }}
        data={drugs}
      />
    </div>
  );
}
