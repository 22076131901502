import React, {useEffect, useState, memo} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { getProtocols } from '../../services/getProtocols';
import { validateSession } from '../../helpers';
import CloneQuestionDialog from './CloneQuestionDialog';

const useStyles = makeStyles((theme) => ({
    nestedQuestions: {
      paddingLeft: theme.spacing(6),
    },
  }));

const NestedListQuestions = memo(({protocol, protocols, folder, folders, setLoading}) => {
  const [childrenQuestions, setChildrenQuestions] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [questionToClone, setQuestionToClone] = useState()

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleClick = (question) => {
    setQuestionToClone(question)
    setOpenDialog(true)
  }

  const classes = useStyles();

  useEffect(() => {
    let isCancelled = false;
    if (protocol.id) {
      if (validateSession()){
        getProtocols(folder.id, protocol.id).then(({data}) => {
        if (!isCancelled){
          let questions = [...data];
          setChildrenQuestions([...questions])
          setLoading(false)
        }
      }).catch(
        (e) => {
          alert('Hubo un problema al intentar traer las preguntas en el navbar.');
          console.log(e)
        }
      );
    }
    }
    return () => {
      isCancelled = true;
    }
  }, [])

  return (
    <>
      {questionToClone && <CloneQuestionDialog protocols={protocols} folders={folders} open={openDialog} handleClose={handleClose} question={questionToClone} folder={folder} protocol={protocol} />}
      <List
        disablePadding
      >
        {childrenQuestions.map((question, index) => (
          <ListItem style={{background: folder.tenant?.colour ? `${folder.tenant?.colour}4d` : ''}} className={classes.nestedQuestions} key={protocol.id+'-'+index}>
            <ListItemIcon style={{minWidth: '10%'}} onClick={() => handleClick(question)}>
              <FileCopyIcon />
            </ListItemIcon>
            <Tooltip placement="right" interactive arrow title={<div style={{ fontSize: "medium" }}>{question.text}</div>} >
              <ListItemText primary={question.med_id} />
            </Tooltip>
          </ListItem>
        ))}
      </List>
    </>
  )
})

export default NestedListQuestions
