import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import { Grid } from '@material-ui/core';
import Navbar from '../components/Navbar/Navbar'
import TagsTable from '../components/TagsMap/TagsTable'
import { validateSession } from '../helpers';

const useStyles = makeStyles(() => ({
  main: {
    margin: '10% 5% 5% 5%',
    maxWidth: '90%',
  },
}));

const TagsMap = () => {
  const classes = useStyles();
  return (
    <>
      {!validateSession() ? <Redirect to="/login" /> : ''}
      <Navbar />
      <Grid container className={classes.main}>
        <Grid item xs={12}>
          <TagsTable/>
        </Grid>
      </Grid>
    </>
  )
}

export default TagsMap
