import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Export from './Export';
import SelectEnvironment from './SelectEnvironment';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: 'auto',
  },
  select: {
    marginTop: '2%'
  }
}));

const ExportProtocols = ({tenantId}) => {
  const classes = useStyles();

  const [environment, setEnvironment] = useState('')
  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <SelectEnvironment setEnvironment={setEnvironment} environment={environment} />
      </div>
      <div className={classes.export}>
        <Export tenantId={tenantId} environment={environment} />
      </div>
    </div>
  )
}

export default ExportProtocols
